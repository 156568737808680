<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-10 h-10"
  >
    <rect width="24" height="24" rx="12" fill="white" />
    <g clip-path="url(#clip0_2202_102)">
      <path
        d="M7.37655 9.81831H4.84414C4.62026 9.81831 4.40555 9.90185 4.24724 10.0506C4.08894 10.1993 4 10.4009 4 10.6112V17.7475C4 18.1681 4.17787 18.5715 4.49448 18.8689C4.8111 19.1663 5.24052 19.3334 5.68828 19.3334H6.53242C6.98017 19.3334 7.40959 19.1663 7.72621 18.8689C8.04282 18.5715 8.22069 18.1681 8.22069 17.7475V10.6112C8.22069 10.4009 8.13176 10.1993 7.97345 10.0506C7.81514 9.90185 7.60043 9.81831 7.37655 9.81831Z"
        :class="`${props.fill ? 'fill-good-green' : 'fill-gray-400'}`"
      />
      <path
        d="M18.3115 9.81831H14.9738L16.289 6.25017C16.3321 5.92366 16.2523 5.59326 16.0634 5.31619C15.8745 5.03912 15.5884 4.83284 15.2546 4.73307C14.9209 4.6333 14.5605 4.64633 14.2358 4.7699C13.9112 4.89347 13.6428 5.11979 13.4771 5.40968C12.5015 6.97375 11.3008 8.40391 9.90897 9.65973V17.2607H10.0052C11.7855 17.7229 14.1423 18.5404 16.3244 18.5404C17.5062 18.5404 17.6683 18.1234 17.9114 17.4962L19.8985 11.9457C19.9912 11.7062 20.021 11.4491 19.9855 11.1965C19.9499 10.9438 19.8501 10.7029 19.6944 10.4941C19.5386 10.2854 19.3317 10.115 19.0909 9.99733C18.8502 9.87969 18.5829 9.81828 18.3115 9.81831Z"
        :class="`${props.fill ? 'fill-good-green' : 'fill-gray-400'}`"
      />
    </g>
    <defs>
      <clipPath id="clip0_2202_102">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: Boolean,
    default: false,
  },
});
</script>
