<template>
  <PageView page-classes="!overflow-x-auto">
    <StatsTopNavBar
      modal-title="Activity - Advanced Filters"
      :tab-items="navBarTabs"
      :selected-tab="activeTab"
      :filters-applied="activityStatsStore.filtersApplied"
    >
      <template #modal-content>
        <ActivityFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between mb-4 w-full">
        <div class="flex gap-2 items-center">
          <h1 class="text-rev-2xl text-primary font-bold">
            Activity {{ activeTab }}
          </h1>
          <div
            class="flex flex-col items-center justify-center bg-gray-200 rounded-full text-white w-5 h-5 relative"
            @mouseenter="openPopup"
            @mouseleave="hidePopup"
          >
            <div
              v-if="showPopup"
              class="w-fit text-black border border-secondary rounded-xl bg-white whitespace-nowrap p-2 absolute left-7"
            >
              Data displayed includes the "Complete" action type by default.
              This can be changed in the advanced filter menu.
            </div>
            <InfoIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
            <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
          </div>
          <div class="flex items-center gap-1 justify-end">
            <span class="text-rev-sm text-gray-500 font-bold"
              >Last Updated:</span
            >
            <span class="text-rev-sm text-gray-400">{{
              activityStatsStore.lastUpdated
            }}</span>
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-4 w-full">
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-sm text-gray-500 font-light">
            October 2024 Total
          </span>
          <StatsValue
            :value="monthlyTotal.value.toString()"
            :caret-ternary="monthlyTotal.caretTernary ?? 0"
            :color-ternary="monthlyTotal.colorTernary ?? 0"
          >
          </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-sm text-gray-500 font-light">
            April 2024 - September 2025 Average
          </span>
          <StatsValue
            :value="previousSixMonthsAverage.value.toString()"
            :caret-ternary="previousSixMonthsAverage.caretTernary ?? 0"
            :color-ternary="previousSixMonthsAverage.colorTernary ?? 0"
          >
          </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-sm text-gray-500 font-light">
            {{ activityStatsStore.currentMonth }} vs 6 Month Average
          </span>
          <StatsValue
            :value="
              activityStatsStore.monthlyVS6MonthAverageCount.value.toString()
            "
            :caret-ternary="
              activityStatsStore.monthlyVS6MonthAverageCount.caretTernary ?? 0
            "
            :color-ternary="
              activityStatsStore.monthlyVS6MonthAverageCount.colorTernary ?? 0
            "
          >
          </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-sm text-gray-500 font-light">
            {{ activityStatsStore.currentMonth }} vs 6 Month Average
          </span>
          <StatsValue
            :value="
              activityStatsStore.monthlyVS6MonthAveragePercent.value.toString()
            "
            :caret-ternary="
              activityStatsStore.monthlyVS6MonthAveragePercent.caretTernary ?? 0
            "
            :color-ternary="
              activityStatsStore.monthlyVS6MonthAveragePercent.colorTernary ?? 0
            "
          >
          </StatsValue>
        </StatsCard>
      </div>
      <div class="flex flex-row gap-4 justify-between">
        <StatsGroupCard size-classes="w-1/2" group-title="Monthly Insights">
          <template
            v-for="(statEntry, index) in activityStatsStore.monthlyInsights"
            :key="index"
          >
            <StatsCard
              container-classes="justify-center items-center rounded-md"
              color-classes="bg-gray-75"
              size-classes="w-1/3 h-24"
              :show-warning="statEntry.showWarning"
            >
              <StatsValue
                :value="statEntry.value.toString()"
                :caret-ternary="statEntry.caretTernary ?? 0"
                :color-ternary="statEntry.colorTernary ?? 0"
              >
              </StatsValue>
              <div class="text-gray-500 font-light text-sm">
                {{ statEntry.labelText }}
              </div>
              <div class="text-gray-500 font-bold text-sm">
                {{ statEntry.textValue }}
              </div>
            </StatsCard>
          </template>
        </StatsGroupCard>
        <StatsGroupCard
          size-classes="w-1/2"
          group-title="Most Active Days of the Week"
        >
          <template
            v-for="(statEntry, index) in activityStatsStore.mostActiveDays"
            :key="index"
          >
            <StatsCard
              container-classes="justify-center items-center rounded-md"
              color-classes="bg-gray-75"
              size-classes="w-1/3 h-24"
            >
              <StatsValue
                :value="statEntry.value.toString()"
                :caret-ternary="statEntry.caretTernary ?? 0"
                :color-ternary="statEntry.colorTernary ?? 0"
              >
              </StatsValue>
              <div class="font-light text-gray-500 text-sm">
                {{ statEntry.textValue }}
              </div>
            </StatsCard>
          </template>
        </StatsGroupCard>
      </div>
      <div class="flex gap-4">
        <StatsGroupCard size-classes="w-3/5" group-title="Leaderboard">
          <div class="flex justify-between w-full gap-4">
            <OverviewTableSection
              :table-data="leaderboardData.slice(0, 5)"
              :start-index="0"
            />
            <OverviewTableSection
              :table-data="leaderboardData.slice(5, 10)"
              :start-index="5"
            />
          </div>
        </StatsGroupCard>
        <StatsGroupCard size-classes="w-2/5" group-title="Top 5 Activity Codes">
          <OverviewTableSection
            :table-data="activityCodeData"
            :start-index="0"
            first-col-header="Code Name"
            second-col-header="Total Actions"
            first-col-key="codeName"
          />
        </StatsGroupCard>
      </div>
    </div>
  </PageView>
</template>
<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import ActivityFilterModal from '@/components/stats/modals/ActivityFilterModal.vue';
import { useActivityStatsStore } from '@/stores/stats/useActivityStats';
import StatsCard from '@/components/stats/Cards/StatsCard.vue';
import StatsValue from '@/components/stats/Misc/StatsValue.vue';
import InfoIcon from '@/assets/new-stats/InfoIcon.vue';
import { ref } from 'vue';
import StatsGroupCard from '@/components/stats/Cards/StatsGroupCard.vue';
import OverviewTableSection from '@/components/stats/table/ActivityOverviewTableSection.vue';

const activityStatsStore = useActivityStatsStore();

const activeTab = 'Overview';
const dateRange = `${activityStatsStore.startDate} - ${activityStatsStore.endDate}`;

const leaderboardData = activityStatsStore.leaderboard;
const activityCodeData = activityStatsStore.activityCodes;
const previousSixMonthsAverage = activityStatsStore.previousSixMonthsAverage;
const monthlyTotal = activityStatsStore.monthlyTotal;

const showPopup = ref(false);

function openPopup() {
  showPopup.value = true;
}

function hidePopup() {
  showPopup.value = false;
}
</script>
<style scoped></style>
