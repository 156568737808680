<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-10 h-10"
  >
    <rect width="24" height="24" rx="12" fill="white" />
    <g clip-path="url(#clip0_2202_107)">
      <path
        d="M18.3117 4.66669H17.4675C17.0197 4.66669 16.5903 4.83377 16.2737 5.13118C15.957 5.42859 15.7792 5.83197 15.7792 6.25257V13.389C15.7792 13.5993 15.8681 13.801 16.0264 13.9497C16.1847 14.0984 16.3994 14.182 16.6233 14.182H19.1558C19.3797 14.182 19.5944 14.0984 19.7527 13.9497C19.9111 13.801 20 13.5993 20 13.389V6.25257C20 5.83197 19.8221 5.42859 19.5055 5.13118C19.1889 4.83377 18.7594 4.66669 18.3117 4.66669Z"
        :class="`${props.fill ? 'fill-bad-red' : 'fill-gray-400'}`"
      />
      <path
        d="M14.0908 6.74181H13.9946C12.2143 6.28032 9.85818 5.45963 7.67601 5.45963C6.49418 5.45963 6.3321 5.87671 6.08982 6.50314L4.10181 12.0537C4.00902 12.2932 3.97904 12.5502 4.01441 12.8028C4.04978 13.0555 4.14946 13.2965 4.305 13.5053C4.46055 13.7141 4.66738 13.8846 4.90797 14.0024C5.14856 14.1202 5.41584 14.1818 5.68715 14.182H9.02668L7.71315 17.7502C7.67032 18.0767 7.75031 18.4069 7.93927 18.6839C8.12824 18.9608 8.4143 19.167 8.74799 19.2669C9.08168 19.3667 9.44201 19.3538 9.76666 19.2305C10.0913 19.1071 10.3599 18.8811 10.5259 18.5915C11.5008 17.0271 12.7014 15.5968 14.0934 14.3413L14.0908 6.74181Z"
        :class="`${props.fill ? 'fill-bad-red' : 'fill-gray-400'}`"
      />
    </g>
    <defs>
      <clipPath id="clip0_2202_107">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: Boolean,
    default: false,
  },
});
</script>
