<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3889 0.439453H14.9444C14.33 0.439453 13.8333 0.937231 13.8333 1.55056C13.8333 2.1639 14.33 2.66168 14.9444 2.66168H16.9544L13.0478 6.59056C12.6133 7.02501 12.6133 7.72723 13.0478 8.16168C13.2644 8.37834 13.5489 8.48723 13.8333 8.48723C14.1178 8.48723 14.4022 8.37834 14.6189 8.16168L18.2778 4.48279V5.99501C18.2778 6.60834 18.7744 7.10612 19.3889 7.10612C20.0033 7.10612 20.5 6.60834 20.5 5.99501V1.55056C20.5 0.937231 20.0033 0.439453 19.3889 0.439453Z"
      :class="props.fillColor"
    />
    <path
      d="M19.3889 13.7728C18.7744 13.7728 18.2778 14.2706 18.2778 14.8839V16.6583L14.6189 12.9872C14.1844 12.5528 13.4822 12.5528 13.0478 12.9872C12.6133 13.4217 12.6133 14.1239 13.0478 14.5583L16.6944 18.2172H14.9444C14.33 18.2172 13.8333 18.715 13.8333 19.3283C13.8333 19.9417 14.33 20.4395 14.9444 20.4395H19.3889C20.0033 20.4395 20.5 19.9417 20.5 19.3283V14.8839C20.5 14.2706 20.0033 13.7728 19.3889 13.7728Z"
      :class="props.fillColor"
    />
    <path
      d="M4.29333 2.66168H6.05556C6.67 2.66168 7.16667 2.1639 7.16667 1.55056C7.16667 0.937231 6.67 0.439453 6.05556 0.439453H1.61111C1.46667 0.439453 1.32222 0.469453 1.18667 0.525009C0.914445 0.637231 0.697778 0.853898 0.585555 1.12612C0.53 1.26168 0.5 1.40612 0.5 1.55056V5.99501C0.5 6.60834 0.996667 7.10612 1.61111 7.10612C2.22556 7.10612 2.72222 6.60834 2.72222 5.99501V4.23279L6.65 8.16168C6.86667 8.37834 7.15111 8.48723 7.43556 8.48723C7.72 8.48723 8.00445 8.37834 8.22111 8.16168C8.65556 7.72723 8.65556 7.02501 8.22111 6.59056L4.29333 2.66168Z"
      :class="props.fillColor"
    />
    <path
      d="M6.65 12.9872L2.72222 16.915V14.8839C2.72222 14.2706 2.22556 13.7728 1.61111 13.7728C0.996667 13.7728 0.5 14.2706 0.5 14.8839V19.3283C0.5 19.9417 0.996667 20.4395 1.61111 20.4395H6.05556C6.67 20.4395 7.16667 19.9417 7.16667 19.3283C7.16667 18.715 6.67 18.2172 6.05556 18.2172H4.56222L8.22111 14.5583C8.65556 14.1239 8.65556 13.4217 8.22111 12.9872C7.78667 12.5528 7.08444 12.5528 6.65 12.9872Z"
      :class="props.fillColor"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  fillColor: {
    type: String,
    default: 'fill-gray-500',
  },
});
</script>
