<template>
  <div class="flex w-fit h-fit items-center text-gray-500 text-sm">
    <div>
      Showing
      <span class="font-bold text-gray-900">
        {{ (page - 1) * props.limit + 1 }} - {{ current_end_range }}
      </span>
      of
      <span class="font-bold text-gray-900">
        {{ records }}
      </span>
    </div>
  </div>
  <nav>
    <ul class="inline-flex items-center -space-x-px text-base h-10">
      <!-- Previous Button -->
      <li>
        <button>
          <a
            id="previous-page-fb-pagination"
            class="flex items-center justify-center px-3 h-10 ms-0 leading-tight text-gray-400 border border-e-0 border-gray-300 rounded-s-lg"
            tabindex="0"
            :class="{
              'cursor-not-allowed': props.page === 1,
              'hover:bg-gray-100 hover:text-gray-700': props.page !== 1,
            }"
            @click="updatePage(props.page - 1)"
          >
            <ArrowLeft />
          </a>
        </button>
      </li>

      <!-- Page Numbers -->
      <template v-for="(item, index) in getNumbersToShow()" :key="index">
        <li
          v-if="item === '...'"
          class="flex items-center justify-center px-3 h-10 leading-tight text-gray-400 border border-gray-300"
        >
          ...
        </li>
        <li
          v-else
          class="flex items-center justify-center px-3 h-10 leading-tight text-gray-400 border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
          :class="item === props.page ? 'bg-gray-300 text-primary' : ''"
          @click="updatePage(item)"
        >
          {{ item }}
        </li>
      </template>

      <!-- Next Button -->
      <li class="mr-20">
        <button>
          <a
            id="next-page-fb-pagination"
            class="flex items-center justify-center px-3 h-10 leading-tight text-gray-400 bg-white border border-gray-300 rounded-e-lg"
            tabindex="0"
            :class="{
              'cursor-not-allowed': props.page === getLast(),
              'hover:bg-gray-100 hover:text-gray-700': props.page !== getLast(),
            }"
            @click="updatePage(props.page + 1)"
          >
            <ArrowRight />
          </a>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed } from 'vue';
import ArrowLeft from '@/assets/new-stats/ArrowLeft.vue';
import ArrowRight from '@/assets/new-stats/ArrowRight.vue';
const props = defineProps({
  page: {
    default: 1,
    type: Number,
  },
  //# of records per page
  limit: {
    default: 10,
    type: Number,
  },
  //Total # of records
  records: {
    default: 0,
    type: Number,
  },
});

const emit = defineEmits(['paginate']);

const current_end_range = computed(() => {
  return props.records < props.page * props.limit
    ? props.records
    : props.page * props.limit;
});

function updatePage(page) {
  if (page > 0 && page < getLast() + 1) {
    emit('paginate', page);
  }
}

function getLast() {
  return Math.ceil(props.records / props.limit);
}

function getNumbersToShow() {
  const numbers = [];
  const lastPage = getLast();
  const currentPage = props.page;
  const delta = currentPage === 1 || currentPage === lastPage ? 2 : 1;

  for (let i = 1; i <= lastPage; i++) {
    if (
      i === 1 || // First page
      i === lastPage || // Last page
      (i >= currentPage - delta && i <= currentPage + delta) // Pages around current
    ) {
      if (numbers.length > 0 && i - numbers[numbers.length - 1] > 1) {
        numbers.push('...');
      }
      numbers.push(i);
    }
  }

  return numbers;
}
</script>

<style scoped></style>
