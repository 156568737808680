<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4123_48318)">
      <path
        d="M0.185948 1.45455L5.63035 8.63637V12.2727L9.36965 15V8.63637L14.8141 1.45455C14.9178 1.31924 14.981 1.1583 14.9963 0.989832C15.0117 0.821361 14.9787 0.65204 14.901 0.500909C14.8233 0.349778 14.7041 0.22283 14.5567 0.13434C14.4093 0.0458511 14.2395 -0.000670277 14.0666 7.29723e-06H0.933438C0.760463 -0.000670277 0.590725 0.0458511 0.443311 0.13434C0.295896 0.22283 0.176651 0.349778 0.0989852 0.500909C0.021319 0.65204 -0.0116884 0.821361 0.00367459 0.989832C0.0190375 1.1583 0.0821615 1.31924 0.185948 1.45455Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4123_48318">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup></script>
