<template>
  <SearchBarVue :show-icon="false" />
  <PiratedConfettiExplosion />
  <aside
    id="default-sidebar"
    class="pb-8 z-20 h-screen bg-primary text-white border-r border-gray-200 transition-all duration-300"
    :class="{ 'w-[22rem]': !collapsed, 'w-20': collapsed }"
    aria-label="Sidenav"
    @mouseenter="expandSidebar"
    @mouseleave="collapseSidebar"
  >
    <div
      class="flex flex-col justify-between overflow-y-hidden py-5 px-3 h-[95%] dark:bg-gray-800 dark:border-gray-700"
      :class="{ 'w-[18rem]': !collapsed, 'w-18': collapsed }"
    >
      <div>
        <ul class="space-y-2">
          <li>
            <div
              class="flex flex-row justify-between items-center p-2 text-base rounded-xl font-normal group"
            >
              <div class="flex flex-row w-16 gap-2 items-center">
                <AuxoIcon v-show="collapsed" class="w-9 h-9 mb-4" />
                <img
                  v-show="!collapsed"
                  src="@/assets/auxo-White-3C.png"
                  class="h-[3.25rem] max-w-48"
                />
              </div>
              <FlowbitePinnedIcon
                v-show="!collapsed"
                :pinned="pinned"
                @click="togglePinned()"
              />
            </div>
          </li>
          <SidebarGoalsFlowbite
            :collapsed="collapsed"
            :collapsed-after-animations="collapsedAfterAnimations"
          />
          <SidebarSearchFlowbite :collapsed="collapsed" />
          <template v-for="navItem in topNavConfig" :key="navItem.name">
            <SidebarDropdownFlowbite
              v-if="
                rbac_store.isFeatureFlagEnabled('SIDEBAR_STAT_EXPANSION') &&
                rbac_store.hasAccessToRoute(navItem.name) &&
                navItem.dropdownItems
              "
              :name="navItem.sidebarName"
              :dropdown-items="navItem.dropdownItems"
              :collapsed="collapsed"
              :collapsed-after-animations="collapsedAfterAnimations"
              :icon="navItem.flowbiteIcon"
              :nested-level="0"
            >
            </SidebarDropdownFlowbite>
            <SidebarItemFlowbite
              v-else-if="
                !navItem.dropdownItems &&
                rbac_store.hasAccessToRoute(navItem.name)
              "
              :path="navItem.path"
              :name="navItem.sidebarName"
              :collapsed="collapsed"
              :collapsed-after-animations="collapsedAfterAnimations"
              :icon="navItem.flowbiteIcon"
            />
          </template>
        </ul>
      </div>
      <div>
        <ul class="pt-5 mt-5 space-y-2 border-b border-white">
          <li>
            <a
              href="https://revology.atlassian.net/servicedesk/customer/portal/1/article/64192734"
              target="_blank"
              class="flex items-center p-2 mb-3 text-base font-normal rounded-lg transition duration-75 hover:bg-secondary group"
              :class="{ 'justify-center': collapsed }"
            >
              <FlowbiteSupportIcon />
              <span v-show="!collapsed" class="ml-3">Support</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="flex justify-center items-center pz-4 space-x-4 w-full pt-2.5 mb-4 lg:flex z-20"
    >
      <!-- These elements will be added later, adding the v-show false for now cause the styling is right
       and I would like to just remove them once we are going to add the profile section -->
      <UserPicture :user="users_store.activeUser" :disable-popper="true" />
      <span
        v-if="false && !collapsed"
        class="w-1 h-3 border-r border-white"
      ></span>
      <div
        v-show="!collapsed"
        class="inline-flex justify-center border-r-1 border-white rounded cursor-pointer hover:bg-secondary"
        @click="signOut"
      >
        Logout
      </div>
    </div>
  </aside>
</template>
<script setup>
import { ref } from 'vue';
import { routes } from '../../router/index';
import { useRoute } from 'vue-router';
import { topNavConfig } from '../../config/navConfig';
import AuxoIcon from '@/assets/AuxoIcon.vue';
import FlowbiteSupportIcon from '@/assets/sidebar-icons/FlowbiteSupportIcon.vue';
import SidebarDropdownFlowbite from './SidebarDropdown-Flowbite.vue';
import SidebarItemFlowbite from './SidebarItem-Flowbite.vue';
import SidebarGoalsFlowbite from './SidebarGoals-Flowbite.vue';
import SidebarSearchFlowbite from './SidebarSearch-Flowbite.vue';
import UserPicture from '../UserPicture.vue';
import { useRbacStore } from '@/stores/useRbac';
import { useUsersStore } from '@/stores/useUsers';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useSessionStorage } from '@vueuse/core';
import FlowbitePinnedIcon from '@/assets/sidebar-icons/FlowbitePinnedIcon.vue';
import SearchBarVue from '../../components/SearchBar.vue';
import PiratedConfettiExplosion from './confetti/PiratedConfettiExplosion.vue';

const rbac_store = useRbacStore();
const gameplan_store = useGamePlanStore();
const users_store = useUsersStore();

const pinned = useSessionStorage('pinnned', false);
const collapsed = ref(!pinned.value);
//This is to fix some weird styling/timing with the items under search
const collapsedAfterAnimations = ref(!pinned.value);
const stats = ref(false);

function togglePinned() {
  pinned.value = !pinned.value;
}

function collapseSidebar() {
  if (!pinned.value && !stats.value) {
    collapsed.value = true;
    setTimeout(() => (collapsedAfterAnimations.value = true), 200);
  }
}

function expandSidebar() {
  collapsed.value = false;
  setTimeout(() => (collapsedAfterAnimations.value = false), 200);
}

async function signOut() {
  if (gameplan_store.editMode) {
    gameplan_store.logoutModal = true;
    return;
  }
  //Using a then to not have the sidebar close as they are logging out
  users_store.logOut().then(() => {
    pinned.value = false;
  });
}

/**
 * Returns All Routes With Sidebar True
 * @returns - Routes With Sidebar True
 */
const getTopNavRoutes = async () => {
  return Promise.all(
    routes
      ?.filter(route => route.sidebarTop == true)
      .map(async route => {
        if (route.featureFlag != null) {
          const featureFlagPass = rbac_store.isFeatureFlagEnabled(
            route.featureFlag
          );
          if (!featureFlagPass) return null;
        }

        return route;
      })
  ).then(routes => routes.filter(v => v));
};
const topNavRoutes = await getTopNavRoutes();
</script>
