<template>
  <div
    class="flex flex-col z-10"
    :class="`${
      leftoverSpace >= TWO_XL_BREAK
        ? 'max-w-[575px] min-w-[575px]'
        : leftoverSpace >= XL_BREAK
        ? 'max-w-[350px] min-w-[350px]'
        : 'w-full'
    }`"
  >
    <!-- Quick View  -->
    <div
      class="w-full mr-2 mb-5 p-2 overflow-visible scrollbar-hide bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <h1 class="ml-2 text-rev-xl text-primary font-semibold">Quick View</h1>

        <div
          class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid whitespace-nowrap"
          :class="getBreakpointClass('grid-cols-2', 'gird-cols-1', true)"
        >
          <template v-for="(field, index) in QUICK_VIEW" :key="field.val">
            <div
              :class="`flex flex-row my-1 items-center ${getBreakpointClass(
                'justify-normal',
                'justify-between',
                true
              )}  ${availableQVClasses[index]}`"
            >
              <div :class="index % 2 === 0 ? 'w-36' : 'w-24'">
                <p
                  class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded w-fit text-rev-sm"
                >
                  {{ field.title }}
                </p>
              </div>
              <div>
                <!-- https://github.com/valgeirb/vue3-popper -->
                <Popper :hover="true" :locked="true" placement="right">
                  <p
                    class="bg-white whitespace-normal max-w-[150px] text-rev-sm text-gray-700"
                  >
                    {{ getWorkItemDetail(field) }}
                  </p>
                  <template #content>
                    <div
                      slot="reference"
                      class="bg-white px-2 rounded font-thin text-xs shadow whitespace-normal max-w-[250px]"
                    >
                      {{ getWorkItemDetail(field) }}
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Transaction Summary  -->
    <div
      v-if="workItems_store?.workItem?.workflow_status !== 'Inactive'"
      class="w-full mr-2 mb-5 p-2 overflow-visible overflow-y-scroll scrollbar-hide bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <div
          class="flex items-center w-full cursor-pointer"
          @click="expandTransactionSummary = !expandTransactionSummary"
        >
          <h1 class="ml-2 text-rev-xl font-semibold">Transaction Summary</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-4 h-4 ml-2"
            :class="expandTransactionSummary === true && 'rotate-180'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid">
          <!-- Transaction Summary: Charges -->
          <div
            v-if="expandTransactionSummary"
            class="flex items-center justify-between my-1 h-auto"
            :class="`${getBreakpointClass('w-56', 'w-full')}`"
          >
            <div class="min-w-[75px]">
              <p
                class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit"
                :class="
                  !expandTransactionSummary ? 'text-rev-sm' : 'text-rev-sm'
                "
              >
                Charges
              </p>
            </div>
            <p
              class="bg-white text-ellipsis overflow-hidden mr-2 w-fit"
              :class="!expandTransactionSummary ? 'text-rev-sm' : 'text-rev-sm'"
            >
              {{
                formatCurrency().format(workItems_store.workItemChargesTotal)
              }}
            </p>
          </div>

          <!-- Transaction Summary: Payments -->
          <div
            v-if="expandTransactionSummary"
            class="flex items-center justify-between my-1 h-auto"
            :class="`${getBreakpointClass('w-56', 'w-full')}`"
          >
            <div class="min-w-[75px]">
              <p
                class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit"
                :class="!expandTransactionSummary ? 'text-lg' : 'text-rev-sm'"
              >
                Payments
              </p>
            </div>
            <p
              class="bg-white text-ellipsis overflow-hidden mr-2 w-fit"
              :class="!expandTransactionSummary ? 'text-xl' : 'text-rev-sm'"
            >
              {{
                formatCurrency().format(workItems_store.workItemPaymentsTotal)
              }}
            </p>
          </div>

          <!-- Transaction Summary: Adjustments -->
          <div
            v-if="expandTransactionSummary"
            class="flex items-center justify-between my-1 h-auto"
            :class="`${getBreakpointClass('w-56', 'w-full')}`"
          >
            <div class="min-w-[75px]">
              <p
                class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit"
                :class="!expandTransactionSummary ? 'text-lg' : 'text-rev-sm'"
              >
                Adjustments
              </p>
            </div>
            <p
              class="bg-white text-ellipsis overflow-hidden mr-2 w-fit"
              :class="!expandTransactionSummary ? 'text-xl' : 'text-rev-sm'"
            >
              {{
                formatCurrency().format(
                  workItems_store.workItemAdjustmentsTotal
                )
              }}
            </p>
          </div>

          <!-- Transaction Summary: Balance -->
          <div
            class="flex items-center justify-between my-1 h-auto"
            :class="`${getBreakpointClass('w-56', 'w-full')}`"
          >
            <div class="min-w-[75px]">
              <p
                class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit"
                :class="
                  !expandTransactionSummary ? 'text-rev-sm' : 'text-rev-sm'
                "
              >
                Balance
              </p>
            </div>
            <p
              class="bg-white text-ellipsis overflow-hidden mr-2 w-fit"
              :class="!expandTransactionSummary ? 'text-rev-sm' : 'text-rev-sm'"
            >
              {{
                getWorkItemDetail({ val: 'current_balance', type: 'currency' })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Payer  -->
    <div
      class="w-full mr-2 mb-5 p-2 overflow-visible bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <div
          class="flex items-center w-full cursor-pointer"
          @click="expandPayer = !expandPayer"
        >
          <h1 class="ml-2 text-rev-xl font-semibold">Payer</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-4 h-4 ml-2"
            :class="expandPayer === true && 'rotate-180'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div
          class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid whitespace-nowrap"
          :class="getBreakpointClass('grid-cols-2', 'grid-cols-1', true)"
        >
          <template v-for="(field, index) in PAYER" :key="field.val">
            <div
              v-if="!field.hide ? true : expandPayer"
              :class="`flex flex-row my-1 items-center ${getBreakpointClass(
                'justify-normal',
                'justify-between',
                true
              )} ${availablePayerClasses[index]}`"
            >
              <div
                :class="
                  index % 2 === 0
                    ? getBreakpointClass('w-36', 'w-fit')
                    : getBreakpointClass('w-28', 'w-fit')
                "
              >
                <p
                  class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded w-fit text-rev-sm"
                >
                  {{ field.title }}
                </p>
              </div>

              <!-- https://github.com/valgeirb/vue3-popper -->
              <Popper :hover="true" :locked="true" placement="right">
                <p
                  class="whitespace-break-spaces mr-2 text-rev-sm"
                  :class="
                    leftoverSpace >= TWO_XL_BREAK
                      ? 'text-left max-w-[100px]'
                      : leftoverSpace >= XL_BREAK
                      ? 'text-right'
                      : leftoverSpace >= 1000
                      ? 'text-left'
                      : leftoverSpace >= 768
                      ? 'text-left max-w-[175px]'
                      : 'text-right max-w-[100px]'
                  "
                >
                  {{ getWorkItemDetail(field) }}
                </p>
                <template #content>
                  <div
                    slot="reference"
                    class="bg-white px-2 rounded font-thin text-xs shadow"
                  >
                    {{ getWorkItemDetail(field) }}
                  </div>
                </template>
              </Popper>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Provider  -->
    <div
      class="w-full mr-2 p-2 mb-5 overflow-visible bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <div
          class="flex items-center w-full cursor-pointer"
          @click="expandProvider = !expandProvider"
        >
          <h1 class="ml-2 text-rev-xl font-semibold">Provider</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-4 h-4 ml-2"
            :class="expandProvider === true && 'rotate-180'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>

        <div
          class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid whitespace-nowrap"
          :class="getBreakpointClass('grid-cols-2', 'grid-cols-1', true)"
        >
          <template v-for="(field, index) in PROVIDER" :key="field.val">
            <div
              v-if="!field.hide ? true : expandProvider"
              :class="`flex flex-row my-1 items-center ${getBreakpointClass(
                'justify-normal',
                'justify-between',
                true
              )} ${availableProviderClasses[index]}`"
            >
              <div :class="index % 2 === 0 ? 'w-36' : 'w-28'">
                <p
                  class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded w-fit text-rev-sm"
                >
                  {{ field.title }}
                </p>
              </div>

              <!-- https://github.com/valgeirb/vue3-popper -->
              <Popper :hover="true" placement="right">
                <p
                  class="whitespace-normal mr-2 text-rev-sm"
                  :class="
                    leftoverSpace >= XL_BREAK
                      ? 'text-left max-w-[100px]'
                      : 'text-right'
                  "
                >
                  {{ getWorkItemDetail(field) }}
                </p>
                <template #content>
                  <div
                    slot="reference"
                    class="bg-white px-2 rounded font-thin text-xs shadow"
                  >
                    {{ getWorkItemDetail(field) }}
                  </div>
                </template>
              </Popper>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Client Defined Fields  -->
    <div
      v-if="showCdfs"
      class="w-full mr-2 mb-5 p-2 overflow-visible scrollbar-hide bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <div
          class="flex items-center w-full cursor-pointer"
          @click="expandClientDefinedFields = !expandClientDefinedFields"
        >
          <h1 class="ml-2 text-rev-xl font-semibold">Client Defined Fields</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-4 h-4 ml-2"
            :class="expandClientDefinedFields === true && 'rotate-180'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>

        <div
          class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid"
          :class="
            getBreakpointClass(
              'justify-between w-full',
              'overflow-hidden text-ellipsis',
              true
            )
          "
        >
          <template v-for="field in clientDefinedFields" :key="field.val">
            <div
              v-if="!field.hide ? true : expandClientDefinedFields"
              class="flex items-start my-1 h-auto"
            >
              <!-- labels longer then 20 charactes will be cut off and use a popper -->
              <div :class="getBreakpointClass('', 'w-full', true)">
                <Popper :hover="true" placement="bottom">
                  <div class="w-full min-w-[170px]">
                    <p
                      class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit text-rev-sm"
                    >
                      {{
                        field.title.length > 20
                          ? field.title.substring(0, 17) + '...'
                          : field.title
                      }}
                    </p>
                  </div>
                  <template #content>
                    <div
                      class="bg-white px-2 w-full rounded font-thin text-xs shadow whitespace-normal"
                    >
                      {{ field.title }}
                    </div>
                  </template>
                </Popper>
              </div>

              <!-- https://github.com/valgeirb/vue3-popper -->

              <Popper :hover="true" placement="right">
                <div
                  class="bg-white text-rev-sm text-gray-700 whitespace-nowrap"
                  :class="
                    getBreakpointClass(
                      'text-left', // Left align at largest screen
                      'max-w-[150px]', // Keep truncation for smaller screens
                      true
                    )
                  "
                >
                  {{
                    getWorkItemCustomDetail(field).length > 20
                      ? getWorkItemCustomDetail(field).substring(0, 17) + '...'
                      : getWorkItemCustomDetail(field)
                  }}
                </div>
                <template #content>
                  <div
                    class="bg-white px-2 rounded font-thin text-xs shadow whitespace-normal"
                  >
                    {{ getWorkItemCustomDetail(field) }}
                  </div>
                </template>
              </Popper>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, inject, computed } from 'vue';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import { useRoute } from 'vue-router';
import Popper from 'vue3-popper';
import { formatCurrency, formatDate } from '@/utils/helpers';

const QUICK_VIEW = [
  { title: 'Account #', val: 'account_number' },
  { title: 'Visit #', val: 'visit_number' },
  { title: 'Medical Record #', val: 'medical_record_number' },
  { title: 'Claim #', val: 'claim_number' },
  { title: 'DOB', val: 'patient_dob', type: 'date' },
  { title: 'Phase', val: 'phase' },
  { title: 'Service Start Date', val: 'service_start_date', type: 'date' },
  { title: 'Status', val: 'status' },
  { title: 'Service End Date', val: 'service_end_date', type: 'date' },
  { title: 'Reason', val: 'reason' },
  { title: 'Score', val: 'score', type: 'round' },
  { title: 'Worklist', val: 'work_list_name' },
];

const PAYER = [
  { title: 'Current Plan', val: 'current_plan_name' },
  { title: 'Policy #', val: 'policy_number' },
  { title: 'Member', val: 'subscriber_name' },
  { title: 'Group #', val: 'group_number' },
  { title: 'Primary Payer', val: 'current_payer_name', hide: true },
  { title: 'Primary Plan', val: 'original_plan_name', hide: true },
  {
    title: 'Coverage Start',
    val: 'coverage_start_date',
    hide: true,
    type: 'date',
  },
  { title: 'Coverage End', val: 'coverage_end_date', hide: true, type: 'date' },
];

const PROVIDER = [
  { title: 'Visit Type', val: 'visit_type' },
  { title: 'Department', val: 'department' },
  { title: 'Billing Provider', val: 'billing_provider' },
  { title: 'Provider NPI', val: 'billing_provider_npi' },
  { title: 'Billing Type', val: 'billing_type', hide: true },
  { title: 'Location', val: 'location', hide: true },
  { title: 'Billing Entity', val: 'billing_entity', hide: true },
  { title: 'Tax ID', val: 'tax_id', hide: true },
];

// build out values below in watch() statemnet
// default to not showing the card, if there are values watch() will set this to true
const clientDefinedFields = ref([]);
const showCdfs = ref(false);

const SM_BREAK = 640;
const XL_BREAK = 1280;
const TWO_XL_BREAK = 1536;

const route = useRoute();

watch(route, async () => {
  // reset all to expanded
  expandPayer.value = true;
  expandProvider.value = true;
  expandTransactionSummary.value = true;
  expandClientDefinedFields.value = true;
});

const workItems_store = useWorkItemsStore();

// builds out custom defined fields array
// Wait until the work item is populated in Pinia before building the custom fields array
watch(
  () => workItems_store.workItem,
  () => {
    const cdfData = workItems_store.workItem?.cdf_ar;
    if (!cdfData) {
      showCdfs.value = false;
      clientDefinedFields.value = [];
      return;
    }
    showCdfs.value = true;
    // what cdfData looks like
    // {"cdf_ar":
    //    {"cdf_1": {"label": "field1", "data_type": "string", "value": "val1"}},
    //    {"cdf_2": {...}},
    //    ...
    // }
    clientDefinedFields.value = Object.values(cdfData).map(
      ({ label, value, data_type }) => ({
        title: label,
        val: value,
        type: data_type,
        hide: true,
      })
    );
    // Post .map() we have something that looks like this to be rendered
    // [{title: "name", val: "Bruce Wayne", type: "string", hide: true}, ...]
  }
);

const expandTransactionSummary = ref(true);
const expandPayer = ref(true);
const expandProvider = ref(true);
const expandClientDefinedFields = ref(true);

const getWorkItemDetail = field => {
  const value = workItems_store.workItem?.[field.val];
  return formatValue(field.type, value);
};

const getWorkItemCustomDetail = field => {
  return formatValue(field.type, field.val);
};

const formatValue = (type, value) => {
  if (type === 'date') {
    return value ? formatDate(value) : '';
  } else if (type === 'round') {
    return value ? Math.round(value) : '';
  } else if (type === 'currency') {
    return value ? formatCurrency().format(value) : '';
  }

  return value;
};

const leftoverSpace = inject('leftoverSpace');

const getBreakpointClass = (class1, class2, smBreak) => {
  if (leftoverSpace.value >= TWO_XL_BREAK) return class1;
  if (leftoverSpace.value >= XL_BREAK) return class2;
  if (smBreak) {
    if (leftoverSpace.value >= SM_BREAK) return class1;
    return class2;
  }
  return class1;
};

//This seems really silly but it was the only way I could get tailwind to cooperate
//It needs to know about the classes at build time and doing it via the index dynamically happens after build
const availableQVClasses = computed(() => [
  'order-0',
  getBreakpointClass('order-1', 'order-2', true),
  getBreakpointClass('order-2', 'order-1', true),
  'order-3',
  'order-4',
  getBreakpointClass('order-5', 'order-8', true),
  getBreakpointClass('order-6', 'order-5', true),
  getBreakpointClass('order-7', 'order-9', true),
  getBreakpointClass('order-8', 'order-6', true),
  getBreakpointClass('order-9', 'order-10', true),
  getBreakpointClass('order-10', 'order-7', true),
  'order-11',
]);

const availablePayerClasses = computed(() => [
  'order-0',
  getBreakpointClass('order-1', 'order-1', true),
  getBreakpointClass('order-2', 'order-2', true),
  'order-3',
  'order-4',
  'order-5',
  'order-6',
  'order-7',
]);

const availableProviderClasses = computed(() => [
  'order-0',
  'order-1',
  getBreakpointClass('order-2', 'order-3', true),
  getBreakpointClass('order-3', 'order-2', true),
  'order-4',
  'order-5',
  'order-6',
  'order-7',
]);
</script>

<style>
.fill-height {
  height: 700px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
