import { defineStore } from 'pinia';
import {
  FAKE_WORK_GAP_DATA,
  fetchAndSortData,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '@/utils/ogFakeData';

export const useWorkGapStore = defineStore('workgap', {
  state: () => ({
    searchLimit: 15,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'user',
    totalRows: 0,
    fakeWorkGapData: [],
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
    selectedUser: null,
    selectedActivityGap: null,
    OGFakeWorkGapData: FAKE_WORK_GAP_DATA,
    filtersApplied: [],
    currentMonth: 'October',
    monthlyTotal: { value: 27, colorTernary: 0 },
    previousYearAverage: { value: 27, colorTernary: 0 },
    monthlyVS6MonthAverageCount: {
      value: 27,
      colorTernary: 2,
      caretTernary: 2,
    },
    monthlyVS6MonthAveragePercent: {
      value: '22.7%',
      colorTernary: 1,
      caretTernary: 1,
    },
    monthlyInsights: [
      {
        key: 'highestMonth',
        value: 27,
        textValue: 'August 2024',
        labelText: 'Highest Month:',
        colorTernary: 1,
      },
      {
        key: 'lowestMonth',
        value: 27,
        textValue: 'October 2024',
        labelText: 'Lowest Month:',
        colorTernary: 2,
      },
      {
        key: 'currentMonth',
        value: 27,
        textValue: 'October 2024',
        labelText: 'Current Month:',
        colorTernary: 0,
      },
    ],
    highOccurrenceDays: [
      { value: 27, textValue: 'Thursday', colorTernary: 0 },
      { value: 27, textValue: 'Monday', colorTernary: 0 },
      { value: 27, textValue: 'Wednesday', colorTernary: 0 },
    ],
    mostOccurrenceUsers: [
      { user: 'Diane', smallhours: '3', largehours: '4', total: '14' },
      { user: 'Casey', smallhours: '6', largehours: '2', total: '10' },
      { user: 'Robert', smallhours: '6', largehours: '2', total: '18' },
      { user: 'Dan', smallhours: '6', largehours: '2', total: '23' },
      { user: 'Sam', smallhours: '6', largehours: '2', total: '16' },
    ],
    baseChartData: [
      { month: '4', activityGap: '1.5 - 3 hours', user: 'Diane' },
      { month: '5', activityGap: '3+ hours', user: 'Casey' },
      { month: '8', activityGap: '1.5 - 3 hours', user: 'Casey' },
      { month: '9', activityGap: '3+ hours', user: 'Sam' },
      { month: '10', activityGap: '1.5 - 3 hours', user: 'Robert' },
      { month: '4', activityGap: '1.5 - 3 hours', user: 'Diane' },
      { month: '5', activityGap: '3+ hours', user: 'Casey' },
      { month: '8', activityGap: '1.5 - 3 hours', user: 'Casey' },
      { month: '9', activityGap: '3+ hours', user: 'Sam' },
      { month: '5', activityGap: '1.5 - 3 hours', user: 'Robert' },

      { month: '6', activityGap: '1.5 - 3 hours', user: 'Diane' },
      { month: '7', activityGap: '3+ hours', user: 'Casey' },
      { month: '8', activityGap: '1.5 - 3 hours', user: 'Casey' },
      { month: '9', activityGap: '3+ hours', user: 'Sam' },
      { month: '5', activityGap: '1.5 - 3 hours', user: 'Robert' },
      { month: '6', activityGap: '1.5 - 3 hours', user: 'Diane' },
      { month: '7', activityGap: '3+ hours', user: 'Casey' },
      { month: '8', activityGap: '1.5 - 3 hours', user: 'Casey' },
      { month: '9', activityGap: '3+ hours', user: 'Sam' },
      { month: '4', activityGap: '1.5 - 3 hours', user: 'Robert' },
    ],
    isLoading: false,
  }),
  getters: {
    getFiltersApplied: state => {
      return state.filtersApplied;
    },
  },
  actions: {
    async fetchSortedPaginatedData(
      page = 1,
      direction = 'asc',
      field = 'user'
    ) {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };

      try {
        const response = await fetchAndSortData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeWorkGapData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
    createSeriesAndCategoriesForChart(
      monthly = true,
      splitBy = null,
      pageLimit = 10,
      pageNumber = 1,
      columnId = null,
      direction = null
    ) {
      let series = {};
      //Using constants for now
      let categories = this.createCategories(
        monthly,
        DEFAULT_START_DATE,
        DEFAULT_END_DATE
      );
      if (splitBy != null) {
        let optionsList = {};
        for (const item of this.baseChartData) {
          if (monthly == 1) {
            let index = Number(item.month) - 4;
            if (optionsList[item[splitBy]] === undefined) {
              optionsList[item[splitBy]] = this.createBaseSeries(
                categories,
                item[splitBy]
              );
            }
            optionsList[item[splitBy]].data[index] += 1;
          } else {
            //Day
            let randomDay = Math.floor(Math.random() * 30);
            let index = (Number(item.month) - 4) * 30 + randomDay;
            if (optionsList[item[splitBy]] === undefined) {
              optionsList[item[splitBy]] = this.createBaseSeries(
                categories,
                item[splitBy]
              );
            }
            optionsList[item[splitBy]].data[index] += 1;
          }
        }
        series = Object.entries(optionsList).map(([key, value]) => value);
      } else {
        let optionsList = {};
        optionsList['Total'] = this.createBaseSeries(categories, 'Total');
        for (const item of this.baseChartData) {
          if (monthly == 1) {
            let index = Number(item.month) - 4;
            optionsList['Total'].data[index] += 1;
          } else {
            //This is really bad, but, it will work for this ticket
            let randomDay = Math.floor(Math.random() * 30);
            let index = (Number(item.month) - 4) * 30 + randomDay;
            optionsList['Total'].data[index] += 1;
          }
        }
        series = Object.entries(optionsList).map(([key, value]) => value);
      }
      let sortSeries = series;

      if (columnId) {
        if (columnId == 'name') {
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.name;
            const valueB = b.name;
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
          //Sort by name
        } else if (columnId == 'total') {
          //Sort by Total
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const valueB = b.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else {
          //Everything else
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data[columnId];
            const valueB = b.data[columnId];
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        }
      }
      sortSeries = sortSeries.slice(
        (pageNumber - 1) * pageLimit,
        pageNumber * pageLimit
      );
      return {
        series: series,
        sortableSeries: sortSeries,
        categories: categories,
      };
    },
    createBaseSeries(categories, optionsName = 'Total') {
      let series = Array.apply(null, Array(categories.length)).map(function () {
        return 0;
      });
      return { name: optionsName, data: series };
    },
    createCategories(monthly, startDate, endDate) {
      let categories = [];
      let dateObject = new Date(startDate);
      let endDateObject = new Date(endDate);
      if (monthly) {
        //Month View
        while (dateObject < endDateObject) {
          categories.push([
            dateObject.toLocaleString('default', { month: 'short' }),
            dateObject.toLocaleString('default', { year: 'numeric' }),
          ]);
          dateObject = new Date(dateObject.setMonth(dateObject.getMonth() + 1));
        }
      } else {
        //Day View
        while (dateObject < endDateObject) {
          categories.push([
            dateObject.toLocaleString('default', {
              month: 'numeric',
              day: 'numeric',
            }),
          ]);
          dateObject = new Date(dateObject.setDate(dateObject.getDate() + 1));
        }
      }
      return categories;
    },
    resetFilters() {
      this.selectedUser = null;
      this.selectedActivityGap = null;

      this.startDate = DEFAULT_START_DATE;
      this.endDate = DEFAULT_END_DATE;
      this.filtersApplied = [];
    },
    applyFilters() {
      //There will be more here but this will do for now
      this.filtersApplied = [{ name: 'Date Range Applied' }];
    },
    fetchAndSortData(fakeBody) {
      const { page, limit, direction, field } = fakeBody;
      const start = (page - 1) * limit;
      const getValue = item => {
        const value = item[field];
        if (field === 'time_since_last_activity') {
          return Number(value.split('min')[0]);
        }
        if (field.includes('activity')) {
          const [datePart, timePart] = value.split(' at ');
          const [month, day, year] = datePart.split(' ');
          const [time, period] = timePart.split(' ');
          let [hours, minutes] = time.split(':');
          hours = parseInt(hours);
          if (period === 'PM' && hours !== 12) hours += 12;
          if (period === 'AM' && hours === 12) hours = 0;
          return new Date(
            `${month} ${day} ${year} ${hours}:${minutes}`
          ).getTime();
        }
        return value;
      };
      const sortedData = FAKE_WORK_GAP_DATA.sort((a, b) => {
        const valueA = getValue(a);
        const valueB = getValue(b);
        const multiplier = direction === 'asc' ? 1 : -1;
        return typeof valueA === 'number'
          ? (valueA - valueB) * multiplier
          : valueA.localeCompare(valueB) * multiplier;
      });
      return {
        fakeData: sortedData.slice(start, start + limit),
        totalRows: FAKE_WORK_GAP_DATA.length,
      };
    },
  },
});
