<template>
  <svg
    :class="props.textColor"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 8"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  textColor: {
    default: 'gray-400',
    type: String,
  },
});
</script>
