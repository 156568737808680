<template>
  <div v-if="!isTopEdits">
    <div
      class="flex py-2"
      :class="{
        'ml-[43px]': !props.level,
        [`ml-[${props.level}em]`]: props.level,
      }"
    >
      <button
        class="px-4 py-1.5 text-sm font-medium text-primary bg-white border border-primary rounded-md hover:bg-gray-100 focus:z-10 mr-2"
        type="button"
        @click="addRule()"
      >
        <font-awesome-icon :icon="faPlus" /> Add New Rule
      </button>

      <button
        v-if="!isTopEdits && level < 2"
        class="px-4 py-1.5 text-sm font-medium text-primary bg-white border border-primary rounded-md hover:bg-gray-100 focus:z-10"
        type="button"
        @click="addGroup()"
      >
        <font-awesome-icon :icon="faPlus" /> Add New Group
      </button>
    </div>
  </div>
  <div v-else-if="!isRoot">
    <button
      class="px-4 py-1.5 text-sm font-medium text-primary bg-white border border-primary rounded-md hover:bg-gray-100 focus:z-10 flex items-center"
      type="button"
      @click="remove"
    >
      <TrashCanPrimary class="h-4 mr-1" />Remove Group
    </button>
  </div>
</template>

<script setup>
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TrashCanPrimary from '@/assets/TrashCanPrimary.vue';

const queryBuilder = useQueryBuilderStore();

const addRule = () => {
  const id = Date.now();
  queryBuilder.addRule(props.level, props.indexChain, props.keyChain, id);
};

const addGroup = () => {
  const id = Date.now();
  queryBuilder.addGroup(props.level, props.indexChain, props.keyChain, id);
};

const remove = () => {
  queryBuilder.remove(props.level, props.indexChain, props.keyChain, true);
};

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  rule: {
    type: Object,
    default: () => {},
  },
  indexChain: {
    type: Array,
    default: () => [],
  },
  keyChain: {
    type: Array,
    default: () => [],
  },
  isRoot: {
    type: Boolean,
    default: false,
  },
  isTopEdits: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped></style>
