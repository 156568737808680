<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0154 14.885H17.7776C18.392 14.885 18.8886 14.3872 18.8886 13.7739C18.8886 13.1605 18.392 12.6628 17.7776 12.6628H13.3332C12.7188 12.6628 12.2222 13.1605 12.2222 13.7739V18.2182C12.2222 18.8316 12.7188 19.3293 13.3332 19.3293C13.9477 19.3293 14.4443 18.8316 14.4443 18.2182V16.456L18.1031 20.1149C18.3198 20.3315 18.6042 20.4404 18.8886 20.4404C19.1731 20.4404 19.4575 20.3315 19.6742 20.1149C20.1086 19.6804 20.1086 18.9782 19.6742 18.5438L16.0154 14.885Z"
      :class="props.fillColor"
    />
    <path
      d="M6.66676 1.5518C6.05344 1.5518 5.55568 2.04957 5.55568 2.6629V4.4251L1.89689 0.766258C1.46246 0.33182 0.760257 0.33182 0.325824 0.766258C-0.108608 1.2007 -0.108608 1.90291 0.325824 2.33735L3.98461 5.99619H2.22244C1.60912 5.99619 1.11136 6.49396 1.11136 7.10728C1.11136 7.72061 1.60912 8.21838 2.22244 8.21838H6.66676C7.28008 8.21838 7.77784 7.72061 7.77784 7.10728V2.6629C7.77784 2.04957 7.28008 1.5518 6.66676 1.5518Z"
      :class="props.fillColor"
    />
    <path
      d="M6.66676 12.6628H2.22244C1.60912 12.6628 1.11136 13.1605 1.11136 13.7739C1.11136 14.3872 1.60912 14.885 2.22244 14.885H3.98461L0.325824 18.5438C-0.108608 18.9782 -0.108608 19.6804 0.325824 20.1149C0.542485 20.3315 0.826921 20.4404 1.11136 20.4404C1.39579 20.4404 1.68023 20.3315 1.89689 20.1149L5.55568 16.456V18.2182C5.55568 18.8316 6.05344 19.3293 6.66676 19.3293C7.28008 19.3293 7.77784 18.8316 7.77784 18.2182V13.7739C7.77784 13.1605 7.28008 12.6628 6.66676 12.6628Z"
      :class="props.fillColor"
    />
    <path
      d="M13.3332 8.21838H17.7776C18.392 8.21838 18.8886 7.72061 18.8886 7.10728C18.8886 6.49396 18.392 5.99619 17.7776 5.99619H16.0154L19.6742 2.33735C20.1086 1.90291 20.1086 1.2007 19.6742 0.766258C19.2397 0.33182 18.5375 0.33182 18.1031 0.766258L14.4443 4.4251V2.6629C14.4443 2.04957 13.9477 1.5518 13.3332 1.5518C12.7188 1.5518 12.2222 2.04957 12.2222 2.6629V7.10728C12.2222 7.72061 12.7188 8.21838 13.3332 8.21838Z"
      :class="props.fillColor"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  fillColor: {
    type: String,
    default: 'fill-gray-500',
  },
});
</script>
