<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8523 4.70571C13.9893 3.76476 12.5846 3.76476 11.7216 4.70571L5.83789 11.1243C5.77903 11.1885 5.73998 11.2641 5.71303 11.3439C5.71138 11.3493 5.70643 11.3529 5.70478 11.3583L4.52826 15.2097C4.46226 15.4251 4.51396 15.6633 4.66137 15.8236C4.76588 15.9382 4.90668 16 5.05024 16C5.10855 16 5.1674 15.9898 5.22405 15.9688L8.7536 14.6846C8.75855 14.6828 8.76185 14.678 8.76625 14.6762C8.83995 14.6474 8.90926 14.6042 8.96811 14.54L14.8523 8.12202C15.7159 7.18048 15.7159 5.64725 14.8523 4.70571ZM10.9334 7.26209L11.3332 7.69836C11.3321 7.69956 11.3305 7.70016 11.3294 7.70136L7.40382 11.9836L7.00505 11.5485L10.9334 7.26209ZM5.91984 14.4512L6.46712 12.6593L7.56168 13.8535L5.91984 14.4512ZM8.57979 13.2672L8.18102 12.8321L12.1066 8.54988C12.1077 8.54868 12.1082 8.54688 12.1093 8.54568L12.5087 8.98135L8.57979 13.2672ZM14.0746 7.27349L13.287 8.13282L11.7111 6.41356L12.4988 5.55423C12.9333 5.08016 13.6401 5.08076 14.074 5.55423C14.5091 6.0283 14.5091 6.79942 14.0746 7.27349Z"
      class="fill-primary"
    />
    <path
      d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10Z"
      class="stroke-primary"
    />
  </svg>
</template>
