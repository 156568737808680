<template>
  <table class="text-left table-fixed">
    <thead>
      <tr>
        <!-- <th class="w-7 place-content-center">
          <input class="cursor-pointer mt-1.5" type="checkbox" />
        </th> -->
        <template
          v-for="(header, templateIndex) in HEADERS"
          :key="templateIndex"
        >
          <TableHeader
            :id="header.key"
            :title="header.value"
            :show-sort="header.sort"
            :width="header.width"
            :type="header.type"
            :source="header.source"
            :title-styling="'text-rev-xl text-gray-700'"
          />
        </template>
      </tr>
    </thead>
    <tbody>
      <tr v-show="assignments_store.isLeadLoading">
        <td :colspan="HEADERS.length">
          <div class="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </td>
      </tr>
      <tr
        v-show="
          !assignments_store.isLeadLoading &&
          assignments_store.readyAssignments.length === 0
        "
      >
        <td :colspan="HEADERS.length">
          <div class="flex flex-col items-center justify-evenly h-40">
            <SolidButton
              class="w-56 h-12 !text-xl py-2"
              :class="{
                'bg-secondary-hover': !hasRequestWorkPermission,
                'border-secondary-hover': !hasRequestWorkPermission,
              }"
              :disabled="!hasRequestWorkPermission"
              @click="refreshTheLead()"
            >
              Feed Me
            </SolidButton>
            <div v-show="!hasRequestWorkPermission">
              You do not have the permission to perform this action
            </div>
            <div v-show="assignmentError" class="text-bad-red">
              {{ assignmentError }}
            </div>
            <span
              v-show="
                assignments_store.readyAssignments.length == 0 &&
                assignments_store.emptyLead
              "
              class="text-bad-red text-lg italic"
            >
              No eligible work items found, please reach out to your leader.
            </span>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody
      v-show="
        !assignments_store.isLeadLoading &&
        assignments_store.readyAssignments.length > 0
      "
      class="text-rev-sm font-normal text-gray-700"
    >
      <template
        v-for="(item, index) in assignments_store.readyAssignments"
        :key="index"
      >
        <tr
          :data-index="index"
          class="z-10 cursor-pointer text-gray-700"
          :class="{
            'bg-gray-100': index % 2 === 1,
            'bg-white': index % 2 === 0,
            'outline outline-1 outline-primary-300 bg-primary-100':
              props.selectedRow === item.work_item_id,
          }"
          @click="
            props.selectedRow === item.work_item_id
              ? emit('showDetails', item.work_item_id)
              : emit('setSelectedRow', item.work_item_id)
          "
        >
          <!-- <td class="w-7 place-content-center">
            <input class="cursor-pointer mt-1" type="checkbox" />
          </td> -->
          <td
            v-for="(header, tableDataIndex) in HEADERS"
            v-show="header.key !== 'assignees'"
            :key="tableDataIndex"
            class="ml-1 w-[200px]"
          >
            <TableCellView :item="item" :header="header" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Popper from 'vue3-popper';
import { useAssignmentsStore } from '@/stores/useAssignments';
import { useUsersStore } from '@/stores/useUsers';
import { useMetrics } from '@/stores/useMetrics';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import { ref, onMounted } from 'vue';
import TableCellView from '../common/TableCellView.vue';
import { ACTIVITY_TYPES } from '@/utils/constants';
import SolidButton from '@/components/buttons/SolidButton.vue';
import { useRbacStore } from '@/stores/useRbac';

const assignments_store = useAssignmentsStore();
const users_store = useUsersStore();
const workItems_store = useWorkItemsStore();
const hasRequestWorkPermission = ref(false);
const metrics_store = useMetrics();
const assignmentError = ref(null);
const rbac_store = useRbacStore();

const emit = defineEmits(['showDetails', 'setSelectedRow']);

const props = defineProps({
  'selected-row': {
    type: Number,
    default: null,
  },
});

onMounted(() => {
  hasRequestWorkPermission.value = rbac_store.hasWriteRole('REQUEST_WORK');
});

async function refreshTheLead() {
  const data = await assignments_store.refreshTheLead();
  if (data.success) {
    await metrics_store.publishEvent('work_items_assigned', {
      actor: users_store.activeUser.username,
      auxo_user_id: users_store.activeUser.auxo_user_id,
      num_assigned: data.data.length,
    });
    data.data.forEach(async work_item => {
      await workItems_store.postActivity(
        work_item.work_item_id,
        ACTIVITY_TYPES['ADDED_TO_LEAD'].id,
        ACTIVITY_TYPES['ADDED_TO_LEAD'].name,
        null,
        null,
        null,
        work_item.assignment_id
      );
    });
  }
}

let number_header_object = {
  key: 'claim_number',
  value: 'Claim Number',
  sort: true,
};
if (rbac_store.isFeatureFlagEnabled('WORK_ITEM_VISIT_COLUMN')) {
  number_header_object = {
    key: 'visit_number',
    value: 'Visit Number',
    sort: true,
  };
}

const HEADERS = [
  number_header_object,
  { key: 'patient_name', value: 'Patient Name', sort: false },
  { key: 'current_plan_name', value: 'Plan', sort: false },
  { key: 'phase', value: 'Phase', sort: false },
  { key: 'status', value: 'Status', sort: false },
  { key: 'reason', value: 'Reason', sort: false },
  {
    key: 'last_auxo_activity_dtm',
    value: 'Last User Activity',
    sort: false,
    type: 'date',
    source: 'lead',
    format: 'Pp',
    truncateTimezone: false,
  },
  { key: 'offset', value: '', sort: false },
  { key: 'is_priority', value: '', sort: false, width: '7em', source: 'lead' },
];
</script>
