<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g id="Group_3949" data-name="Group 3949" transform="translate(-1802 -283)">
      <rect
        id="Rectangle_966"
        data-name="Rectangle 966"
        width="25"
        height="25"
        rx="5"
        transform="translate(1802 283)"
        class="fill-secondary"
      />
      <path
        id="Path_1291"
        data-name="Path 1291"
        d="M1.48,0V-14.2H7.5a4.03,4.03,0,0,1,1.81.41,4.682,4.682,0,0,1,1.43,1.08,5.1,5.1,0,0,1,.94,1.52,4.572,4.572,0,0,1,.34,1.71,4.928,4.928,0,0,1-.32,1.75,4.859,4.859,0,0,1-.9,1.51A4.422,4.422,0,0,1,9.4-5.16a4.05,4.05,0,0,1-1.8.4H4.24V0ZM4.24-7.18h3.2A1.581,1.581,0,0,0,8.71-7.8a2.566,2.566,0,0,0,.51-1.68,2.767,2.767,0,0,0-.16-.97,2.357,2.357,0,0,0-.42-.73,1.671,1.671,0,0,0-.61-.45,1.787,1.787,0,0,0-.71-.15H4.24Z"
        transform="translate(1807.75 302.6)"
        class="fill-white"
      />
    </g>
  </svg>
</template>
