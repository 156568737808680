<template>
  <div class="flex gap-4 pt-2">
    <SolidButton
      class="px-5"
      type="button"
      @click="emits('showSetRules', true)"
    >
      Set Rules
    </SolidButton>

    <SolidButton
      class="px-5"
      type="button"
      @click="emits('showGetRules', true)"
    >
      Get Rules
    </SolidButton>
    <SolidButton
      class="px-5"
      type="button"
      @click="
        () => {
          const ruleValidity = queryBuilder.validateRules();
          queryBuilder.setAttempt(true);

          const ruleMessage = `Rules are ${
            ruleValidity ? 'valid' : 'invalid'
          }.`;
          console.debug(ruleMessage);
          if (ruleValidity) toast.success(ruleMessage);
          else toast.error(ruleMessage);

          const worklistValidity = queryBuilder.validateWorklist(
            route.name === 'editWorklist'
          );
          queryBuilder.setAttempt(true);

          const wlMessage = `Worklist fields are ${
            worklistValidity ? 'valid' : 'invalid'
          }.`;
          console.debug(wlMessage);
          if (worklistValidity) toast.success(wlMessage);
          else toast.error(wlMessage);

          if (ruleValidity && worklistValidity) {
            console.warn('Save not implemented.');
          }
        }
      "
    >
      Validate Inputs
    </SolidButton>
    <SolidButton
      class="px-5"
      type="button"
      @click="
        () => {
          gameplan_store.testCriteria(queryBuilder.query);
        }
      "
    >
      Test Criteria
    </SolidButton>
    <SolidButton
      class="px-5"
      type="button"
      @click="
        () => {
          if (route.name === 'editWorklist') {
            let valid = queryBuilder.validateWorklist(true);
            console.log(valid);
          } else if (route.name == 'newWorklist') {
            let valid = queryBuilder.validateWorklist(false);
            console.log(valid);
          }
        }
      "
    >
      Validate Worklist
    </SolidButton>
    <SolidButton
      class="px-5"
      type="button"
      @click="
        () => {
          if (route.name === 'editWorklist') {
            gameplan_store.editWorklist(
              queryBuilder.worklist,
              queryBuilder.query
            );
          } else if (route.name == 'newWorklist') {
            gameplan_store.saveWorklist(
              queryBuilder.worklist,
              queryBuilder.query
            );
          }
        }
      "
    >
      Save Worklist
    </SolidButton>
  </div>
</template>

<script setup>
import SolidButton from '@/components/buttons/SolidButton.vue';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

const queryBuilder = useQueryBuilderStore();
const emits = defineEmits(['showGetRules', 'showSetRules']);
const gameplan_store = useGamePlanStore();
const route = useRoute();
</script>

<style scoped></style>
