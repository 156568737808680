<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24.004"
    viewBox="0 0 24 24.004"
  >
    <g
      id="Group_3867"
      data-name="Group 3867"
      transform="translate(-1765.001 -294.998)"
    >
      <path
        id="Union_14"
        data-name="Union 14"
        d="M-3391-16307v-17h-5v-5h-17v17h5v5h17m1,1h-19v-5h-5v-19h19v5h5Z"
        transform="translate(5179 16625)"
        class="fill-gray-500"
      />
      <path
        id="Rectangle_958"
        data-name="Rectangle 958"
        d="M1,1V18H18V1H1M0,0H19V19H0Z"
        transform="translate(1770 300)"
        class="fill-gray-500"
      />
      <path
        id="Path_1283"
        data-name="Path 1283"
        d="M4.5,4.5h.9V7.8H8.7v.9H5.4V12H4.5V8.7H1.2V7.8H4.5Z"
        transform="translate(1775 301)"
        class="fill-gray-500"
      />
    </g>
  </svg>
</template>
