<template>
  <div class="w-full">
    <div
      class="flex bg-gray-100 w-full justify-between p-2 rounded-t-md text-gray-500 font-semibold border-b border-gray-200"
    >
      <span>{{ props.firstColHeader }}</span>
      <span>{{ props.secondColHeader }}</span>
    </div>
    <div
      v-for="(user, index) in props.tableData"
      :key="index"
      class="flex justify-between p-2 border-b border-gray-200"
    >
      <span class="px-2"
        >{{ startIndex + index + 1 }}. {{ user[props.firstColKey] }}</span
      >
      <span class="text-secondary font-bold">{{ user.total }}</span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  startIndex: {
    type: Number,
    default: 1,
  },
  firstColHeader: {
    type: String,
    default: 'Username',
  },
  secondColHeader: {
    type: String,
    default: 'Total',
  },
  firstColKey: {
    type: String,
    default: 'username',
  },
});
</script>
