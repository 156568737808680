<template>
  <PageView page-classes="!overflow-x-auto">
    <StatsTopNavBar
      :tab-items="navBarTabs"
      :selected-tab="activeTab"
      :reset-callback="workGapStore.resetFilters"
      :filters-applied="workGapStore.getFiltersApplied"
    >
      <template #modal-content>
        <WorkGapFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex items-center justify-between mb-4 w-full">
      <h1 class="text-rev-2xl text-primary font-bold">
        Work Gap {{ activeTab }}
      </h1>
      <div class="flex items-center gap-1">
        <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
        <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
      </div>
    </div>
    <div class="bg-white p-4 rounded-lg w-full min-w-fit">
      <table class="text-sm text-left text-gray-500 ![--tw-drop-shadow:none]">
        <thead class="text-sm bg-white">
          <tr>
            <template v-for="(header, index) in WORK_GAP_HEADERS" :key="index">
              <TableHeader
                :id="header.key"
                :title="header.value"
                :show-sort="header.sort"
                :width="header.width"
                :style="header.style"
                class="px-4 py-3 bg-gray-100"
                @sort="sortFields"
              />
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(item, index) in workGapStore.fakeWorkGapData"
            :key="index"
          >
            <tr :data-index="index" class="border-b">
              <td
                v-for="(header, tableDataIndex) in WORK_GAP_HEADERS"
                :key="tableDataIndex"
                class="ml-1 px-4"
              >
                <StatsTableCell
                  :item="item"
                  :header="header"
                  :width="header.width"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="flex pt-4 justify-between items-center">
        <StatsPagination
          :records="workGapStore.totalRows"
          :limit="Number(workGapStore.searchLimit)"
          :page="workGapStore.searchPage"
          @paginate="paginate"
        />
      </div>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import TableHeader from '@/components/table/TableHeader.vue';
import { useWorkGapStore } from '@/stores/stats/useWorkGapStats';
import StatsTableCell from '@/components/stats/table/StatsTableCell.vue';
import StatsPagination from '@/components/stats/table/StatsPagination.vue';
import { onMounted } from 'vue';
import WorkGapFilterModal from '@/components/stats/modals/WorkGapFilterModal.vue';

const workGapStore = useWorkGapStore();

const activeTab = 'Detail';
const dateRange = `${workGapStore.startDate} - ${workGapStore.endDate}`;
const WORK_GAP_HEADERS = [
  { key: 'user', value: 'User', width: '16rem', sort: true },
  {
    key: 'prev_activity',
    value: 'Previous Activity',
    width: '16rem',
    sort: true,
  },
  { key: 'activity', value: 'Activity', width: '16rem', sort: true },
  {
    key: 'time_since_last_activity',
    value: 'Time Since Last Activity',
    width: '16rem',
    sort: true,
  },
];

const sortFields = (order = 'asc', field) => {
  workGapStore.fetchSortedPaginatedData(1, order, field);
};

function paginate(page) {
  workGapStore.fetchSortedPaginatedData(
    page,
    workGapStore.searchDirection,
    workGapStore.searchField
  );
}

onMounted(() => {
  paginate(workGapStore.searchPage);
});
</script>
<style scoped></style>
