<template>
  <div
    :class="isHovering ? 'z-[2]' : 'z-1'"
    class="relative"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <table
      class="w-full text-left border-0 relative cursor-default table-fixed"
    >
      <thead class="text-sm sticky top-[-2em] bg-white">
        <tr>
          <th class="w-8"></th>
          <template v-for="(header, index) in WORKLISTS_HEADERS" :key="index">
            <TableHeader
              :id="header.key"
              :title="header.value"
              :type="header.type"
              :show-sort="header.sort"
              :style="
                header.key === 'work_list_assignee' ? 'justify-center' : null
              "
              :width="header.width"
              :title-styling="'text-rev-xl text-gray-700'"
            />
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-show="worklists_store.isLoading || users_store.usersLoading">
          <td :colspan="WORKLISTS_HEADERS.length + 1">
            <div class="flex items-center justify-center h-20">
              <LoadingSpinner />
            </div>
          </td>
        </tr>
      </tbody>

      <tbody>
        <tr
          v-show="
            !(worklists_store.isLoading || users_store.usersLoading) &&
            worklists_store.worklists.length === 0
          "
        >
          <td :colspan="WORKLISTS_HEADERS.length + 2">
            <div class="flex items-center justify-center h-20">
              <p>No Worklists</p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody
        v-show="!(worklists_store.isLoading || users_store.usersLoading)"
        class="text-xs"
      >
        <template
          v-for="(item, index) in worklists_store.worklists.map(worklist => {
            const { bold_end, bold_start } = calculateRowStyle(worklist) || {};
            return {
              ...worklist,
              bold_end,
              bold_start,
            };
          })"
          :key="index"
        >
          <tr
            :data-index="index"
            class="z-10 text-rev-sm font-normal text-gray-700"
            :class="{
              'bg-gray-100': index % 2 === 1,
              'bg-white': index % 2 === 0,
              'outline outline-1 outline-primary-300 bg-primary-100':
                highlightedRow === item.work_list_id,
              'worklist-starting-soon bold-start-timest': item.bold_start,
              'worklist-ending-soon bold-end-timest': item.bold_end,
            }"
            @click="
              highlightedRow === item.work_list_id
                ? setSelectedWorklistId(item.work_list_id)
                : setHighlightedRowId(item.work_list_id)
            "
          >
            <td class="w-8"></td>
            <td
              v-for="(header, tableDataIndex) in WORKLISTS_HEADERS"
              v-show="header.key !== 'assignees'"
              :key="tableDataIndex"
              class="ml-1"
            >
              <TableCellVue :item="item" :header="header" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useWorklistsStore } from '@/stores/useWorklists';
import { useUsersStore } from '@/stores/useUsers';
import { onMounted, onUnmounted, ref } from 'vue';
import 'vue3-toastify/dist/index.css';
import TableCellVue from '../common/TableCellView.vue';

const worklists_store = useWorklistsStore();

const users_store = useUsersStore();

const isHovering = ref(false);

const emit = defineEmits(['setHighlightedRow']);

const props = defineProps({
  username: {
    type: String,
    default: undefined,
  },
  highlightedRow: {
    type: Number,
    default: null,
  },
  setHighlightedRow: {
    type: Function,
    default: () => 1,
  },
  auxo_user_id: {
    type: String,
    default: null,
  },
});

const WORKLISTS_HEADERS = [
  { key: 'work_list_name', value: 'Worklist', sort: false },
  { key: 'owner', value: 'Owner', sort: false },
  {
    key: 'total_balance',
    value: 'Current Balance',
    sort: false,
    type: 'currency',
  },
  {
    key: 'total_work_items',
    value: 'Work Item Count',
    sort: false,
    type: 'count',
  },
  {
    key: 'start_timest',
    value: 'Starts',
    sort: false,
    type: 'date',
  },
  {
    key: 'end_timest',
    value: 'Expires',
    sort: false,
    type: 'date',
  },
  { key: 'work_list_assignee', value: 'Users', sort: false },
  {
    key: 'is_priority',
    value: '',
    sort: false,
    width: '4em',
  },
];

onMounted(async () => {
  worklists_store.isLoading = true;

  if (props.auxo_user_id) {
    await worklists_store.fetchUserWorklists(props.auxo_user_id);
  }
  worklists_store.isLoading = false;
});

onUnmounted(() => {
  worklists_store.worklists = [];
});

function setHighlightedRowId(worklistId) {
  emit('setHighlightedRow', worklistId);
}

function calculateRowStyle(worklist) {
  const currentDate = new Date().setHours(0, 0, 0, 0);
  // Changed from set hours to adding in a time string to fix an issue with timezone rolling days back 1
  // Associated ticket CPRD-1202
  const expirationDate = new Date(worklist.end_timest + 'T00:00:00');
  const startDate = new Date(worklist.start_timest + 'T00:00:00');

  const diffExpiration = expirationDate - currentDate;
  const diffStart = startDate - currentDate;

  const daysDiffExpiration = Math.floor(diffExpiration / (1000 * 60 * 60 * 24));
  const daysDiffStart = Math.floor(diffStart / (1000 * 60 * 60 * 24));

  if (daysDiffStart > 0) {
    return {
      bold_start: true,
    };
  } else if (daysDiffExpiration >= -1 && daysDiffExpiration < 3) {
    return {
      bold_end: true,
    };
  } else {
    return null;
  }
}
</script>

<style scoped>
.bold-start-timest #start_timest-table-cell {
  font-weight: bold;
}
.bold-end-timest #end_timest-table-cell {
  font-weight: bold;
}

.worklist-starting-soon td div:not(#dropdown-table-cell) {
  @apply text-secondary;
}

.worklist-ending-soon td div:not(#dropdown-table-cell) {
  @apply text-bad-red;
}
</style>
