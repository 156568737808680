<template>
  <div class="flex rounded-md">
    <button
      class="px-3 py-1 rounded-s-md border"
      :class="{
        ['bg-primary text-white']: selectedButton == 'left',
        ['bg-gray-200 text-gray-600']: selectedButton == 'right',
      }"
      @click="toggle('left')"
    >
      {{ props.leftButtonText }}
    </button>
    <button
      class="px-3 py-1 rounded-e-md border"
      :class="{
        ['bg-primary text-white ']: selectedButton == 'right',
        ['bg-gray-200 text-gray-600']: selectedButton == 'left',
      }"
      @click="toggle('right')"
    >
      {{ props.rightButtonText }}
    </button>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const props = defineProps({
  leftButtonText: {
    type: String,
    default: '',
  },
  rightButtonText: {
    type: String,
    default: '',
  },
  defaultSelectedButton: {
    type: String,
    default: 'left',
  },
});

const selectedButton = ref(props.defaultSelectedButton);

const toggle = button => {
  selectedButton.value = button;
  emit('toggle', button);
};

const emit = defineEmits(['toggle']);
</script>
<style scoped></style>
