<template>
  <BaseButton
    :type="props.type"
    :class="[
      props.class,
      props.disabled
        ? 'bg-transparent'
        : 'bg-transparent hover:bg-gray-100 hover:text-gray-900',
    ]"
    class=""
    :override-size-class="props.overrideSizeClass"
    :disabled="props.disabled"
  >
    <slot />
  </BaseButton>
</template>
<script setup>
import BaseButton from './BaseButtonFlowBite.vue';

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  overrideSizeClass: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
</script>
