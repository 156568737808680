<template>
  <PageView page-classes="!overflow-x-auto" body-classes="min-w-[72rem]">
    <StatsTopNavBar :tab-items="navBarTabs" :selected-tab="activeTab" />
    <div class="flex items-center mb-4 w-fit">
      <p class="text-rev-2xl text-primary font-bold">Denials {{ activeTab }}</p>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';

const activeTab = 'Breakout';
</script>
<style scoped></style>
