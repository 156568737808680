<template>
  <BaseButton
    :type="props.type"
    class="border"
    :class="{
      [props.class]: true,
      [props.textClass]: true,
      'bg-primary border-primary': props.variant == 'primary',
      'bg-tertiary border-tertiary !text-gray-900': props.variant == 'tertiary',
      'hover:bg-primary-hover': !props.disabled,
      'hover:bg-tertiary/80': !props.disabled && props.variant == 'tertiary',
      '!bg-gray-300 !text-gray-100 !border-none': props.disabled,
    }"
    :disabled="props.disabled"
    :data_modal_target="props.data_modal_target"
    :data_modal_toggle="props.data_modal_toggle"
  >
    <slot />
  </BaseButton>
</template>
<script setup>
import BaseButton from './BaseButtonFlowBite.vue';

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    default: 'primary',
  },
  textClass: {
    type: String,
    default: 'text-white',
  },
  type: {
    type: String,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  data_modal_target: {
    type: String,
    default: '',
  },
  data_modal_toggle: {
    type: String,
    default: '',
  },
});
</script>
