<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="25"
    viewBox="0 0 96 25"
  >
    <g id="Group_3949" data-name="Group 3949" transform="translate(-1719 -283)">
      <rect
        id="Rectangle_966"
        data-name="Rectangle 966"
        width="96"
        height="25"
        rx="5"
        transform="translate(1719 283)"
        class="fill-secondary"
      />
      <text
        id="Priority"
        transform="translate(1767 301)"
        class="fill-white"
        font-size="16"
        font-family="Arial, Helvetica, sans-serif"
        font-weight="bold"
        letter-spacing="0.05em"
      >
        <tspan x="-41" y="0">PRIORITY</tspan>
      </text>
    </g>
  </svg>
</template>
