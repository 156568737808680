<template>
  <ModalComponentFlowBite modal_id="advanced-filter-modal" class="justify-end">
    <div class="bg-white w-full rounded-md p-5 flex flex-col">
      <div class="flex flex-row justify-between">
        <span class="font-bold text-gray-400 mb-6"> {{ modalTitle }} </span>
        <span
          class="cursor-pointer text-gray-400"
          @click="closeAdvancedFilterModal"
        >
          <CloseIcon2 />
        </span>
      </div>
      <slot name="modal-content" />
    </div>
  </ModalComponentFlowBite>
  <nav
    class="flex flex-col justify-between mb-5 bg-white mx-[-2.5rem] mt-[-2rem]"
  >
    <div class="flex flex-row justify-between">
      <div class="flex flex-row justify-between gap-4 px-10 py-6">
        <div class="flex flex-row">
          <template v-for="tab in tabItems">
            <SolidButtonFlowBite
              v-if="tab.name == selectedTab"
              class="bg-primary p-2 py-2 rounded-lg border-white whitespace-nowrap"
              :key="tab.name + 'selected'"
            >
              {{ tab.name }}
            </SolidButtonFlowBite>
            <TransparentButtonFlowBite
              v-else
              class="bg-gray-100 !text-black p-2 py-2 border-transparent whitespace-nowrap"
              :key="tab.name + 'not-selected'"
              @click="router.push(tab.route)"
            >
              {{ tab.name }}
            </TransparentButtonFlowBite>
          </template>
        </div>
      </div>
      <div class="flex flex-row px-10 py-6">
        <SolidButtonFlowBite
          class="bg-secondary p-2 py-2 gap-2 rounded-lg text-black border-white whitespace-nowrap"
          text-class="!text-black"
          @click="openAdvancedFilterModal"
        >
          <FilterIcon></FilterIcon>
          Advanced Filters
        </SolidButtonFlowBite>
      </div>
    </div>
    <div
      class="flex flex-row justify-end px-10 py-4 border-t-2 border-t-solid border-t-grey-50 gap-2"
    >
      <div
        v-for="filter in filtersApplied"
        :key="filter.name"
        class="flex flex-row bg-secondary/20 rounded-md p-1 px-2 gap-2 justify-center items-center"
      >
        <span class="font-bold text-sm">{{ filter.name }}</span>
        <!-- This should be changed from reset callback to something else in the future but this should do for now -->
        <span class="cursor-pointer" @click="resetCallback">
          <CloseIcon2 />
        </span>
      </div>
    </div>
  </nav>
</template>
<script setup>
import SolidButtonFlowBite from '../buttons/SolidButtonFlowBite.vue';
import TransparentButtonFlowBite from '../buttons/TransparentButtonFlowBite.vue';
import FilterIcon from '@/assets/misc-icons/FilterIcon.vue';
import CloseIcon2 from '@/assets/CloseIcon2.vue';
import ModalComponentFlowBite from '../ModalComponentFlowBite.vue';
import router from '@/router';
import { ref, onMounted } from 'vue';
import { Modal } from 'flowbite';

const filterModal = ref(null);

onMounted(async () => {
  const options = {
    backdrop: 'static',
  };

  const instanceOptions = {
    id: 'advanced-filter-modal',
    override: true,
  };
  filterModal.value = new Modal(
    document.getElementById('advanced-filter-modal'),
    options,
    instanceOptions
  );
});

function openAdvancedFilterModal() {
  filterModal.value.show();
}

function closeAdvancedFilterModal() {
  filterModal.value.hide();
}

const props = defineProps({
  tabItems: {
    type: Array,
    default: () => [],
  },
  selectedTab: {
    type: String,
    default: '',
  },
  modalTitle: {
    type: String,
    default: '',
  },
  filtersApplied: {
    type: Array,
    default: () => [],
  },
  resetCallback: {
    type: Function,
    default: () => {},
  },
  applyCallback: {
    type: Function,
    default: () => {},
  },
});
</script>
<style scoped></style>
