import StatsIcon from '@/assets/sidebar-icons/StatsIcon.vue';
import TheLeadIcon from '@/assets/sidebar-icons/TheLeadIcon.vue';
import UsersIcon from '@/assets/sidebar-icons/UsersIcon.vue';
import WorklistsIcon from '@/assets/sidebar-icons/WorklistsIcon.vue';
import OrgSettingsIcon from '@/assets/sidebar-icons/OrgSettingsIcon.vue';
import LoginView from '@/views/auth/LoginView.vue';
import LogoutView from '@/views/auth/LogoutView.vue';
import DocumentView from '@/views/document/DocumentView.vue';
import LeadView from '@/views/lead/LeadView.vue';
import WorkItem from '@/views/lead/WorkItem.vue';
import StatsView from '@/views/stats/StatsView.vue';
import UsersView from '@/views/users/UsersView.vue';
import UserDetail from '@/views/users/UserDetail.vue';
import AdminPanelView from '@/views/admin-panel/AdminPanelView.vue';
import GamePlan from '@/views/refactor/GamePlan.vue';
import NotFoundPage from '@/views/common/NotFoundPage.vue';
import UserForbiddenPage from '@/views/common/UserForbiddenPage.vue';
import UpsertWorkListView from '@/views/refactor/UpsertWorkListView';
import HighFiveIcon from '@/assets/sidebar-icons/HighFiveIcon.vue';
import GamePlanIcon from '@/assets/sidebar-icons/GamePlanIcon.vue';
import FlowbiteUsersIcon from '@/assets/sidebar-icons/FlowbiteUsersIcon.vue';
import FlowbiteStatsIcon from '@/assets/sidebar-icons/FlowbiteStatsIcon.vue';
import FlowbiteOrgSettingsIcon from '@/assets/sidebar-icons/FlowbiteOrgSettingsIcon.vue';

export const topNavConfig = [
  {
    path: '/lead',
    name: 'lead',
    sidebar: true,
    sidebarTop: true,
    icon: <TheLeadIcon class="w-7 h-7" />,
    flowbiteIcon: <HighFiveIcon class="w-7 h-7" />,
    sidebarName: 'High Five',
  },
  {
    path: '/gameplan',
    name: 'gameplan',
    sidebar: true,
    sidebarTop: true,
    icon: <WorklistsIcon class="w-7 h-7" />,
    flowbiteIcon: <GamePlanIcon class="w-7 h-7" />,
    sidebarName: 'Game Plan',
  },
  {
    path: '/users',
    name: 'users',
    sidebar: true,
    sidebarTop: true,
    icon: <UsersIcon class="w-7 h-7" />,
    flowbiteIcon: <FlowbiteUsersIcon class="w-7 h-7" />,
    sidebarName: 'Users',
  },
  {
    path: '/stats',
    name: 'stats',
    sidebar: true,
    sidebarTop: true,
    icon: <StatsIcon class="w-7 h-7" />,
    flowbiteIcon: <FlowbiteStatsIcon class="w-7 h-7" />,
    sidebarName: 'Stats',
  },
  {
    name: 'statsNew',
    sidebar: true,
    sidebarTop: true,
    icon: <StatsIcon class="w-7 h-7" />,
    flowbiteIcon: <FlowbiteStatsIcon class="w-7 h-7" />,
    sidebarName: 'Stats',
    dropdownItems: [
      {
        path: '/stats/ar',
        name: 'ar',
        sidebarName: 'AR',
      },
      {
        path: '/stats/denials',
        name: 'denials',
        sidebarName: 'Denials',
      },
      {
        name: 'workforceManagement',
        sidebarName: 'Workforce Management',
        dropdownItems: [
          {
            path: '/stats/workforce-management/activity',
            name: 'activity',
            sidebarName: 'Activity',
          },
          {
            path: '/stats/workforce-management/gap',
            name: 'workGap',
            sidebarName: 'Work Gap',
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    sidebar: true,
    sidebarTop: true,
    icon: <OrgSettingsIcon />,
    flowbiteIcon: <FlowbiteOrgSettingsIcon />,
    sidebarName: 'Organization Settings',
  },
];

//This should be updated as nav items that require storage are updated
//This is just to clear out the storage on logout
export const navSessionStorageNames = [
  'pinned-dropdown-Workforce Management',
  'pinned-dropdown-Stats',
];
