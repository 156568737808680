<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10Z"
      class="stroke-bad-red"
    />
    <path
      d="M14.0741 7.10526H12.037V6.05263C12.037 5.77346 11.9297 5.50572 11.7387 5.30831C11.5477 5.1109 11.2886 5 11.0185 5H8.98148C8.71135 5 8.45229 5.1109 8.26128 5.30831C8.07027 5.50572 7.96296 5.77346 7.96296 6.05263V7.10526H5.92593C5.79086 7.10526 5.66133 7.16071 5.56583 7.25942C5.47032 7.35812 5.41667 7.49199 5.41667 7.63158C5.41667 7.77117 5.47032 7.90504 5.56583 8.00374C5.66133 8.10244 5.79086 8.15789 5.92593 8.15789H6.43519V13.9474C6.43519 14.2265 6.54249 14.4943 6.7335 14.6917C6.92451 14.8891 7.18358 15 7.45371 15H12.5463C12.8164 15 13.0755 14.8891 13.2665 14.6917C13.4575 14.4943 13.5648 14.2265 13.5648 13.9474V8.15789H14.0741C14.2091 8.15789 14.3387 8.10244 14.4342 8.00374C14.5297 7.90504 14.5833 7.77117 14.5833 7.63158C14.5833 7.49199 14.5297 7.35812 14.4342 7.25942C14.3387 7.16071 14.2091 7.10526 14.0741 7.10526ZM8.98148 6.05263H11.0185V7.10526H8.98148V6.05263ZM12.5463 13.9474H7.45371V8.15789H12.5463V13.9474Z"
      class="fill-bad-red"
    />
    <path
      d="M8.98148 8.68421C8.84642 8.68421 8.71689 8.73966 8.62138 8.83837C8.52588 8.93707 8.47222 9.07094 8.47222 9.21053V12.8947C8.47222 13.0343 8.52588 13.1682 8.62138 13.2669C8.71689 13.3656 8.84642 13.4211 8.98148 13.4211C9.11655 13.4211 9.24608 13.3656 9.34158 13.2669C9.43709 13.1682 9.49074 13.0343 9.49074 12.8947V9.21053C9.49074 9.07094 9.43709 8.93707 9.34158 8.83837C9.24608 8.73966 9.11655 8.68421 8.98148 8.68421Z"
      class="fill-bad-red"
    />
    <path
      d="M11.0185 8.68421C10.8835 8.68421 10.7539 8.73966 10.6584 8.83837C10.5629 8.93707 10.5093 9.07094 10.5093 9.21053V12.8947C10.5093 13.0343 10.5629 13.1682 10.6584 13.2669C10.7539 13.3656 10.8835 13.4211 11.0185 13.4211C11.1536 13.4211 11.2831 13.3656 11.3786 13.2669C11.4741 13.1682 11.5278 13.0343 11.5278 12.8947V9.21053C11.5278 9.07094 11.4741 8.93707 11.3786 8.83837C11.2831 8.73966 11.1536 8.68421 11.0185 8.68421Z"
      class="fill-bad-red"
    />
  </svg>
</template>
