export const ACTIVITY_TYPES = {
  ADDED_TO_LEAD: {
    id: 1,
    name: 'Added to Lead',
  },
  ADDED_TO_WIP: {
    id: 2,
    name: 'Added to WIP',
  },
  COMPLETED: {
    id: 3,
    name: 'Completed',
  },
  NOTE: {
    id: 4,
    name: 'Note',
  },
  REMOVED_FROM_WIP: {
    id: 5,
    name: 'Removed From WIP',
  },
  CHECK_CLAIM_STATUS: {
    id: 6,
    name: 'Check Claim Status',
  },
};

/*
 * An enumeration to map user role abbreviations to user role titles.
 * Do not change order of titles.  This will impact helpers.formatUserRoles (Users View)
 */
export const USER_ROLE_TITLES = {
  REP: 'Contributor',
  SUP: 'Supporter',
  MGR: 'Leader',
  ADM: 'Admin',
};

export const toasts = {
  DEFAULT_SUCCESS: 'Success!',
  DEFAULT_ERROR: 'Error!',
  RULES_ERROR: 'Error fetching rules!',
  PRIORITY_RULES_ERROR: 'Error fetching priority rules!',
  TIMEOUT:
    'Your request seems to be taking longer than anticipated. Please check back in a few minutes.',
};

export const numericHeaders = ['currency', 'count'];
export const centeredHeaders = ['rank', 'dropdown', 'users', 'assignees'];

export const chatAIConfig = {
  mercy: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        endpoint: 'rspGuidance',
      },
    ],
  },
  hancock: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        endpoint: 'rspGuidance',
      },
    ],
  },
  rrh: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        endpoint: 'rspGuidance',
      },
    ],
  },
  dev: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        endpoint: 'rspGuidance',
      },
    ],
    gamePlan: [
      {
        questionText: 'How do I evaluate this work strategy?',
      },
    ],
  },
  default: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        endpoint: 'rspGuidance',
      },
    ],
    gamePlan: [
      {
        questionText: 'How do I evaluate this work strategy?',
      },
    ],
  },
};

// Used by ActivityActionAndNote for first dropdown
export const ActivityDropdown = [
  { id: 3, text: 'Complete', activity_type: 'Completed' },
  { id: 2, text: 'Add to WIP', activity_type: 'Added to WIP' },
  { id: 4, text: 'Add Note', activity_type: 'Note' },
];

export const DeletedActivityString = 'Note removed by Admin per user request.';

export const DEV_ENVS = [
  'dev',
  'eng1',
  'eng2',
  'eng3',
  'eng4',
  'eng1-e2',
  'eng1-w2',
  'eng2-w2',
  'eng3-w2',
  'eng4-w2',
  'cloudops',
  'cloudops-e2',
  'cloudops-w2',
];

export const TEST_ENVS = ['client-product', 'cp02', 'cpt01-w2', 'cpt02-w2'];

//These are the same brand colors in the tailwind config
//For places where colors can't be tailwinded
export const PRIMARY = '#004B87';
export const SECONDARY = '#14B1E7';
export const TERTIARY = '#FCC917';
export const GOAL_TEAL = '#29CCB6';
export const GREEN = '#09A33E';
export const RED = '#EF4444';
export const PINK = '#C44ECC';
export const PURPLE = '#6819A4';
export const CYAN = '#2A909B';
export const UGLY_YELLOW = '#C38513';
export const RUST = '#A44C36';
export const GRAY_400 = '#9CA3AF';
