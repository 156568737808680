<template>
  <ModalComponent
    title="Set Rules"
    :modal-active="props.show"
    :close="props.close"
    :custom-style="{
      minWidth: '800px',
      maxHeight: '600px',
      overflow: 'scroll',
    }"
  >
    <form @submit.prevent="setRulesSchema">
      <textarea
        v-model="rules"
        class="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-primary focus:border-primary"
      ></textarea>
      <div class="mt-5 flex justify-between">
        <OutlinedButton
          class="w-36 mr-2"
          type="button"
          @click="() => props.close()"
        >
          Cancel
        </OutlinedButton>
        <SolidButton class="w-36" type="submit">Submit</SolidButton>
      </div>
    </form>
  </ModalComponent>
</template>

<script setup>
import { ref } from 'vue';
import ModalComponent from '../../components/ModalComponent.vue';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { toast } from 'vue3-toastify';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';

const rules = ref(null);
const queryBuilder_store = useQueryBuilderStore();
const verify = () => {
  if (!rules.value) {
    toast.error('Must input rules!');
    return false;
  }
  try {
    JSON.parse(rules.value);
  } catch (err) {
    toast.error('Invalid JSON!');
    return false;
  }
  return true;
};

const setRulesSchema = () => {
  if (!verify()) {
    props.close();
    return false;
  }
  queryBuilder_store.setRulesSchema(JSON.parse(rules.value));
  rules.value = null;
  props.close();
};

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  close: {
    type: Function,
    default: () => 1,
  },
});
</script>
