<template>
  <BaseButton
    :type="props.type"
    class="bg-primary text-white border border-primary"
    :class="{
      [props.class]: true,
      'hover:bg-primary-hover hover:border-primary-hover': !props.disabled,
    }"
    :disabled="props.disabled"
    :data_modal_target="props.data_modal_target"
    :data_modal_toggle="props.data_modal_toggle"
  >
    <slot />
  </BaseButton>
</template>
<script setup>
import BaseButton from './BaseButton.vue';

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  data_modal_target: {
    type: String,
    default: '',
  },
  data_modal_toggle: {
    type: String,
    default: '',
  },
});
</script>
