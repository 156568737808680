<template>
  <div
    ref="scroll"
    :class="{
      error: props.error,
      'focused-input': props.focused,
      'unfocused-input': !props.focused,
      'payer-plan-input': true,
    }"
  >
    <v-select
      :model-value="payerPlans"
      :options="InfiniteScrollOptions"
      multiple
      :filter="searchPayerPlans"
      :close-on-select="false"
      :deselect-from-dropdown="true"
      :clear-search-on-select="true"
      :disabled="props.disabled"
      :selectable="option => !isParentChecked(option)"
      :map-keydown="keydownHandlers"
      :dropdown-should-open="
        ({ noDrop, open, mutableLoading }) => {
          if (props.disabled) return false;
          if (open) setOpen(true);
          else setOpen(false);
          return noDrop ? false : (open && !mutableLoading) || focused;
        }
      "
      @search="
        (search, loading) => {
          const isSearching = search.length > 0;
          searching = isSearching;
          if (isSearching) {
            expanded = [...parentValues];
          } else {
            expanded = [];
          }
        }
      "
      @update:modelValue="payerPlans => updateVal(payerPlans)"
    >
      <!-- https://vue-select.org/api/slots.html#selected-option-container -->
      <template
        #selected-option-container="{
          option,
          multiple,
          disabled: isDisabled,
          deselect,
        }"
      >
        <div v-show="showSelectedOption(option)" class="vs__selected">
          {{
            option.level == 0
              ? option.label
              : formatChildLabel(option.child_label)
          }}
          <button
            v-if="multiple && !props.disabled"
            ref="deselectButtons"
            :disabled="isDisabled"
            type="button"
            class="vs__deselect"
            :title="`Deselect ${option.child_label || option.label}`"
            :aria-label="`Deselect ${option.child_label || option.label}`"
            @click="
              () => {
                deselect(option);
              }
            "
          >
            <CloseIcon2 />
          </button>
        </div>
      </template>

      <template #option="option">
        <div
          :style="{
            'margin-left': `${option.level * 2}em`,
            display: showOption(option) ? null : 'none',
            padding: '3px 20px',
          }"
        >
          <button
            v-if="option.level < 1"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();

                if (expanded.find(el => isEqual(el, option.value))) {
                  expanded = [];
                } else {
                  expanded = [option.value];
                }
              }
            "
          >
            <img
              :src="DownArrowSVG"
              class="h-3 mr-1 rotate-0"
              :class="{
                '!-rotate-90': !isExpanded(option),
              }"
            />
          </button>
          <input
            v-if="!option.isPageIndicator"
            type="checkbox"
            class="pointer-events-none"
            :checked="isChecked(option) || isParentChecked(option)"
            :disabled="isParentChecked(option)"
            :indeterminate="isIndeterminate(option) == true"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            "
          />
          {{ option.isPageIndicator ? pageNumber : option.label }}
        </div>
      </template>
      <template #open-indicator="{ attributes }">
        <button
          v-if="payerPlans?.length && !props.disabled"
          @click="
            e => {
              e.preventDefault();
              e.stopPropagation();
              reset();
              return false;
            }
          "
        >
          <img :src="CloseIconSVG" class="w-4 h-4 mr-1" />
        </button>

        <img
          :src="DownArrowSVG"
          v-bind="attributes"
          class="h-3 cursor-pointer ml-1"
        />
      </template>
      <template #list-header>
        <li v-show="scrollHasPrevPage" ref="loadPrev" data-scroll-dir="up"></li>
      </template>
      <template #list-footer>
        <li
          v-show="scrollHasNextPage"
          ref="loadNext"
          data-scroll-dir="down"
        ></li>
      </template>
    </v-select>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed, nextTick, useTemplateRef } from 'vue';
import { useScroll, useThrottleFn } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useLookupStore } from '@/stores/useLookups';
import DownArrowSVG from '@/assets/down-arrow.svg';
import CloseIconSVG from '@/assets/close-icon.svg';
import CloseIcon2 from '@/assets/CloseIcon2.vue';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

const payerPlans = ref([]);
const expanded = ref([]);
const payerPlanOptions = ref([]);
const parentOptions = ref([]);
const parentValues = ref([]);
const childOptions = ref([]);
const searching = ref(false);

// Infintie Scroll
const route = useRoute();
const nextObserver = ref(null);
const prevObserver = ref(null);
const InfiniteScrollLimit = ref(30);
const InfiniteScrollStartIndex = ref(0);
const loadNextRef = useTemplateRef('loadNext');
const loadPrevRef = useTemplateRef('loadPrev');
const scroll = ref(null);
const { y } = useScroll(scroll, { behavior: 'auto' });

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
  setValue: {
    type: Function,
    default: () => {},
  },
});

const lookup_store = useLookupStore();

const InfiniteScrollOptions = computed(() =>
  payerPlanOptions.value.slice(
    InfiniteScrollStartIndex.value,
    InfiniteScrollStartIndex.value + InfiniteScrollLimit.value
  )
);

const scrollHasNextPage = computed(
  () =>
    InfiniteScrollStartIndex.value + InfiniteScrollLimit.value <
    payerPlanOptions.value.length
);

const scrollHasPrevPage = computed(() => InfiniteScrollStartIndex.value > 0);

// Infinite scroll callback
const infiniteScroll = useThrottleFn(async ([{ isIntersecting, target }]) => {
  if (!isIntersecting) return;

  const ul = target.offsetParent || target.closest('.overflow-auto');
  if (!ul) return;

  const direction = target.getAttribute('data-scroll-dir');

  if (direction === 'up') {
    InfiniteScrollStartIndex.value -= 10;

    // Await rendering updates
    await nextTick();

    // Adjust the scroll position to maintain continuity
    const selectedContainerEl = document.querySelectorAll(
      '.vs__selected-options'
    )[1];

    // most of the time this is subtracting 0 because the selected options container should be hidden
    // when out of sight
    // when getting closer to the top of the list it starts to render again and can lead to choppiness
    y.value = 270 - selectedContainerEl.clientHeight;
  } else {
    InfiniteScrollStartIndex.value += 10;

    // Await rendering updates
    await nextTick();

    // Preserve scroll position
    y.value = 175;
  }
}, 30); // Throttle to limit execution frequency

// Save the top level parent plans for list reset purposes
// Set options to only Parents
onMounted(() => {
  parentOptions.value = lookup_store.getPayerPlanOptions.filter(
    option => option.level == 0
  );
  payerPlanOptions.value = parentOptions.value;
  parentValues.value = parentOptions.value.map(o => o.value);

  if (route.name === 'editWorklist' || route.name === 'newWorklist') {
    // Infinite Scroll Observers
    nextObserver.value = new IntersectionObserver(infiniteScroll, {
      rootMargin: '150px',
    });
    prevObserver.value = new IntersectionObserver(infiniteScroll, {
      rootMargin: '500px',
    });
  }
});

// Init or disconnect intersection observers based on focus state
watch(
  () => props.focused,
  async isFocused => {
    if (!nextObserver.value) return;
    if (isFocused) {
      await nextTick();
      nextObserver.value.observe(loadNextRef.value);
      prevObserver.value.observe(loadPrevRef.value);
    } else {
      if (nextObserver.value) {
        nextObserver.value.disconnect();
      }
      if (prevObserver.value) {
        prevObserver.value.disconnect();
      }
      InfiniteScrollStartIndex.value = 0;
    }
  }
);

/** The selected options container tweaks out when scrolling upwards
 *  to mitigate this hide it when it doesn't need to be rendered
 */
watch(
  () => scrollHasPrevPage.value,
  async val => {
    const selectedContainerEl = document.querySelectorAll(
      '.vs__selected-options'
    )[1];
    const actionsEl = document.querySelectorAll('.vs__actions')[1];
    if (val) {
      selectedContainerEl.style.display = 'none';
      actionsEl.style.display = 'none';
    } else {
      selectedContainerEl.style.display = 'flex';
      actionsEl.style.display = 'flex';
    }
  }
);

// Splice in children options when parent is expanded
watch(
  () => expanded.value,
  newValue => {
    const getPayerPlans = lookup_store.getPayerPlanOptions;
    const selectedPayerId = newValue[0]?.payer_code;

    const plans = getPayerPlans.filter(
      option =>
        option.level === 1 && option.parent.value.payer_code === selectedPayerId
    );

    plans.sort((a, b) => a.label.split('-')[0] - b.label.split('-')[0]);
    childOptions.value = plans;
    const index = parentOptions.value.map(e => e.value).indexOf(newValue[0]);

    payerPlanOptions.value = [...parentOptions.value];

    payerPlanOptions.value.splice(index + 1, 0, ...plans);
  }
);

// Watch parent query builder to update state
watch(
  () => props.value,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    else {
      const newMapping = uniq(
        props.value
          ?.map(el =>
            lookup_store.getPayerPlanOptions.find(option =>
              isEqual(option.value, el)
            )
          )
          .flatMap(option => {
            if (option.level == 0) return [option];
            return [option, ...option.children];
          })
          .filter(e => e),
        e => e.value
      );

      const oldMapping = payerPlans.value;

      if (isEqual(newMapping, oldMapping)) return;
      else payerPlans.value = newMapping;
    }
  }
);

const formatChildLabel = label => {
  if (label.length <= 60) {
    return label;
  } else {
    return label.substring(0, 60) + '...';
  }
};

// Reset component state
const reset = () => {
  payerPlans.value = [];
  expanded.value = [];
  props.setValue([]);
};

// Is a checkbox checked
const isChecked = option => {
  return (
    payerPlans.value.findIndex(el => isEqual(el.value, option.value)) !== -1
  );
};

// Is a checkbox's parent checked
const isParentChecked = option => {
  if (option.isPageIndicator) return true;
  return (
    payerPlans.value.findIndex(el =>
      isEqual(el.value, option.parent?.value)
    ) !== -1
  );
};

// Is a checkbox indeterminate
const isIndeterminate = option => {
  return (
    option.children.some(
      child =>
        payerPlans.value.findIndex(el => isEqual(el.value, child.value)) !== -1
    ) && isChecked(option) !== true
  );
};

// Search all branches of the tree on a match, using parent_ids
function searchPayerPlans(options, search) {
  options = lookup_store.getPayerPlanOptions;
  const matchingPayerPlans = options
    .filter(el => {
      if (el.label.toLowerCase().trim().includes(search.toLowerCase().trim()))
        return true;
    })
    .flatMap(el => [el, ...el.children]);

  const newOptions = options.filter(
    el =>
      matchingPayerPlans.find(match => isEqual(match.value, el.value)) ||
      matchingPayerPlans.find(match => isEqual(match?.parent?.value, el?.value))
  );

  return newOptions;
}

// Is a chevron expanded
const isExpanded = option => {
  return expanded.value.find(el => isEqual(el, option.value));
};

// Returns true if an option is expanded
const showOption = option => {
  return (
    expanded.value.find(el => isEqual(el, option.parent?.value)) ||
    option.level == 0 ||
    option.isPageIndicator
  );
};

// Returns true if a selected should be shown
const showSelectedOption = option => {
  const parentIsChecked =
    payerPlans.value.findIndex(el =>
      isEqual(option.parent?.value, el.value)
    ) !== -1;

  return !parentIsChecked;
};

/**
 * updateVal is called on a change of the selected options, and dictate how payerPlans.value
 * will update.
 *
 * @param {*} options All selected options
 */
function updateVal(options) {
  const previousValues = payerPlans.value;
  const newValues = options;

  // Remove child values from deselected parent nodes
  function getFilteredOptions(options) {
    const optionsToRemove = previousValues
      .filter(
        previousValue =>
          !newValues
            .map(el => el.value)
            .find(el => isEqual(el, previousValue.value))
      )
      .flatMap(val => {
        return [val.parent, ...val.children].filter(e => e);
      });

    return options.filter(
      option => !optionsToRemove.find(el => isEqual(el.value, option.value))
    );
  }
  // Add parent vales from selected child nodes (when all are selected)
  function getParentParentSelections(options) {
    return lookup_store.getPayerPlanOptions.filter(parent => {
      // If all children exist in value return true
      return (
        parent.level < 1 &&
        parent.children.every(el =>
          getFilteredOptions(options).find(selection =>
            isEqual(el.value, selection.value)
          )
        )
      );
    });
  }
  const filteredOptions = getFilteredOptions(options);

  // Update payerPlans.value, check uniqueness
  payerPlans.value = uniqWith(
    [...filteredOptions, ...getParentParentSelections(filteredOptions)],
    (a, b) => isEqual(a, b)
  );

  props.setValue(
    payerPlans.value.filter(el => showSelectedOption(el)).map(el => el.value)
  );
}

function keydownHandlers(map) {
  return {
    ...map,
    //  delete, we prevent this.maybeDeleteValue()
    8: () => {},

    //  tab
    9: () => this.onTab(),

    //  enter
    13: e => {
      e.preventDefault();
      return this.typeAheadSelect();
    },

    //  esc
    27: () => this.onEscape(),

    //  up
    38: e => {
      e.preventDefault();
      return this.typeAheadUp();
    },

    //  down
    40: e => {
      e.preventDefault();
      return this.typeAheadDown();
    },
  };
}
</script>

<style>
.vs__dropdown-option--disabled {
  background: white !important;
  color: black !important;
}

.vs__dropdown-option--highlight {
  @apply !bg-secondary;
  color: black !important;
}

.vs__dropdown-option--deselect {
  @apply !bg-gray-300;
}

.focused-input .vs__selected-options,
.focused-input .vs__dropdown-toggle {
  height: inherit !important;
}

.focused-input {
  height: 24rem !important;
  overflow: auto;
}

.unfocused-input .vs__selected-options,
.unfocused-input .vs__dropdown-toggle {
  height: 36px !important;
  overflow: hidden;
}

.payer-plan-input .vs__dropdown-option {
  padding: 0px;
}

.payer-plan-input .vs__selected {
  height: fit-content;
}

.payer-plan-input::-webkit-scrollbar {
  display: none;
}
</style>
