<template>
  <PageView page-classes="!overflow-x-auto">
    <StatsTopNavBar
      modal-title="Work Gap - Advanced Filters"
      :tab-items="navBarTabs"
      :selected-tab="activeTab"
      :filters-applied="workGapStore.filtersApplied"
    >
      <template #modal-content>
        <WorkGapFilterModal />
      </template>
    </StatsTopNavBar>
    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between mb-4 w-full">
        <h1 class="text-rev-2xl text-primary font-bold">
          Work Gap {{ activeTab }}
        </h1>
        <div class="flex items-center gap-1">
          <span class="text-rev-sm text-gray-500 font-bold">Date Range:</span>
          <span class="text-rev-sm text-gray-400">{{ dateRange }}</span>
        </div>
      </div>
      <div class="flex flex-row gap-4 w-full">
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-sm text-gray-500 font-light">
            October 2024 Total
          </span>
          <StatsValue
            :value="workGapStore.monthlyTotal.value.toString()"
            :caret-ternary="workGapStore.monthlyTotal.caretTernary ?? 0"
            :color-ternary="workGapStore.monthlyTotal.colorTernary ?? 0"
          >
          </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-sm text-gray-500 font-light">
            April 2024 - September 2025 Average
          </span>
          <StatsValue
            :value="workGapStore.previousYearAverage.value.toString()"
            :caret-ternary="workGapStore.previousYearAverage.caretTernary ?? 0"
            :color-ternary="workGapStore.previousYearAverage.colorTernary ?? 0"
          >
          </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-sm text-gray-500 font-light">
            October 2025 vs 6 Month Average
          </span>
          <StatsValue
            :value="workGapStore.monthlyVS6MonthAverageCount.value.toString()"
            :caret-ternary="
              workGapStore.monthlyVS6MonthAverageCount.caretTernary ?? 0
            "
            :color-ternary="
              workGapStore.monthlyVS6MonthAverageCount.colorTernary ?? 0
            "
          >
          </StatsValue>
        </StatsCard>
        <StatsCard size-classes="w-1/4 h-24" :show-warning="true">
          <span class="text-sm text-gray-500 font-light">
            October 2025 vs 6 Month Average
          </span>
          <StatsValue
            :value="workGapStore.monthlyVS6MonthAveragePercent.value.toString()"
            :caret-ternary="
              workGapStore.monthlyVS6MonthAveragePercent.caretTernary ?? 0
            "
            :color-ternary="
              workGapStore.monthlyVS6MonthAveragePercent.colorTernary ?? 0
            "
          >
          </StatsValue>
        </StatsCard>
      </div>
      <div class="flex flex-row gap-4 justify-between">
        <StatsGroupCard size-classes="w-1/2" group-title="Monthly Insights">
          <template
            v-for="(statEntry, index) in workGapStore.monthlyInsights"
            :key="index"
          >
            <StatsCard
              container-classes="justify-center items-center rounded-md"
              color-classes="bg-gray-75"
              size-classes="w-1/3 h-24"
            >
              <StatsValue
                :value="statEntry.value.toString()"
                :caret-ternary="statEntry.caretTernary ?? 0"
                :color-ternary="statEntry.colorTernary ?? 0"
              >
              </StatsValue>
              <div class="text-gray-500 font-light text-sm">
                {{ statEntry.labelText }}
              </div>
              <div class="font-bold text-sm">
                {{ statEntry.textValue }}
              </div>
            </StatsCard>
          </template>
        </StatsGroupCard>
        <StatsGroupCard size-classes="w-1/2" group-title="High Occurrence Days">
          <template
            v-for="(statEntry, index) in workGapStore.highOccurrenceDays"
            :key="index"
          >
            <StatsCard
              container-classes="justify-center items-center rounded-md"
              color-classes="bg-gray-75"
              size-classes="w-1/3 h-24"
            >
              <StatsValue
                :value="statEntry.value.toString()"
                :caret-ternary="statEntry.caretTernary ?? 0"
                :color-ternary="statEntry.colorTernary ?? 0"
              >
              </StatsValue>
              <div class="font-light text-gray-500 text-sm">
                {{ statEntry.textValue }}
              </div>
            </StatsCard>
          </template>
        </StatsGroupCard>
      </div>
      <StatsGroupCard
        size-classes="w-full"
        group-title="Users With The Most Occurrences"
      >
        <table
          class="w-1/2 text-sm text-left rounded-md text-gray-500 ![--tw-drop-shadow:none]"
        >
          <thead class="text-sm bg-white rounded-md">
            <tr>
              <template
                v-for="(header, index) in WORK_GAP_HEADERS"
                :key="index"
              >
                <TableHeader
                  :id="header.key"
                  :centered="header.key == 'user' ? false : true"
                  :title="header.value"
                  :width="header.width"
                  :style="header.style"
                  class="px-4 py-3 bg-gray-100"
                />
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in fakeWorkGapData" :key="index">
              <tr :data-index="index" class="border-b">
                <td
                  v-for="(header, tableDataIndex) in WORK_GAP_HEADERS"
                  :key="tableDataIndex"
                  class="ml-1 px-4 border-2 border-solid border-gray-100"
                >
                  <StatsTableCell
                    :item="item"
                    :header="header"
                    :width="header.width"
                    :text-color="header.key == 'total' ? 'secondary' : 'black'"
                    :span-classes="'font-bold text-center'"
                    :div-classes="
                      header.key == 'user' ? '' : 'justify-self-center'
                    "
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <table
          class="w-1/2 text-sm text-left rounded-lg text-gray-500 ![--tw-drop-shadow:none]"
        >
          <thead class="text-sm bg-white">
            <tr>
              <template
                v-for="(header, index) in WORK_GAP_HEADERS"
                :key="index"
              >
                <TableHeader
                  :id="header.key"
                  :centered="header.key == 'user' ? false : true"
                  :title="header.value"
                  :width="header.width"
                  :style="header.style"
                  class="px-4 py-3 bg-gray-100"
                />
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in fakeWorkGapData" :key="index">
              <tr :data-index="index" class="border-b">
                <td
                  v-for="(header, tableDataIndex) in WORK_GAP_HEADERS"
                  :key="tableDataIndex"
                  class="ml-1 px-4 border-2 border-solid border-gray-100"
                >
                  <StatsTableCell
                    :item="item"
                    :header="header"
                    :width="header.width"
                    :text-color="header.key == 'total' ? 'secondary' : 'black'"
                    :span-classes="'font-bold text-center'"
                    :div-classes="
                      header.key == 'user' ? '' : 'justify-self-center'
                    "
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </StatsGroupCard>
    </div>
  </PageView>
</template>

<script setup>
import StatsTopNavBar from '@/components/stats/StatsTopNavBar.vue';
import PageView from '../../../components/PageVue.vue';
import navBarTabs from './navSettings.js';
import StatsCard from '@/components/stats/Cards/StatsCard.vue';
import StatsGroupCard from '@/components/stats/Cards/StatsGroupCard.vue';
import StatsTableCell from '@/components/stats/table/StatsTableCell.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import StatsValue from '@/components/stats/Misc/StatsValue.vue';
import { useWorkGapStore } from '@/stores/stats/useWorkGapStats';
import WorkGapFilterModal from '@/components/stats/modals/WorkGapFilterModal.vue';

const workGapStore = useWorkGapStore();

const WORK_GAP_HEADERS = [
  { key: 'user', value: 'User' },
  { key: 'smallhours', value: '1.5 - 3 hours' },
  { key: 'largehours', value: '3+ hours' },
  { key: 'total', value: 'Total' },
];
const fakeWorkGapData = [
  { user: 'Diane', smallhours: '3', largehours: '3', total: '7' },
  { user: 'Casey', smallhours: '6', largehours: '2', total: '10' },
  { user: 'Robert', smallhours: '6', largehours: '2', total: '18' },
  { user: 'Dan', smallhours: '6', largehours: '2', total: '23' },
  { user: 'Sam', smallhours: '6', largehours: '2', total: '16' },
];

const dateRange = `${workGapStore.startDate} - ${workGapStore.endDate}`;

const activeTab = 'Overview';
</script>
<style scoped></style>
