<template>
  <div class="relative p-4 bg-white rounded-lg shadow sm:p-5">
    <!-- Modal header -->
    <div
      class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5"
    >
      <h3 class="text-lg font-semibold text-primary">
        {{ adminStore.modalAction == 'edit' ? 'Update user' : 'Add new user' }}
      </h3>
    </div>
    <!-- Modal body -->
    <form :onsubmit="$event => submit($event)">
      <div class="grid gap-5 mb-5 sm:grid-cols-2">
        <div>
          <BaseInput
            id="firstName"
            v-model="userState.firstName.input"
            label="First Name"
            type="text"
            placeholder="First Name"
            :tabindex="1"
            :required="true"
            :error="
              !userState.firstName.isValid &&
              userState.firstName.message.length > 0
            "
            :error-message="userState.firstName.message"
            :is-valid="userState.firstName.isValid"
            :blur="() => validateInput('firstName')"
          />
        </div>
        <div>
          <BaseInput
            id="lastName"
            v-model="userState.lastName.input"
            label="Last Name"
            type="text"
            placeholder="Last Name"
            :tabindex="2"
            :required="true"
            :error="
              !userState.lastName.isValid &&
              userState.lastName.message.length > 0
            "
            :error-message="userState.lastName.message"
            :is-valid="userState.lastName.isValid"
            :blur="() => validateInput('lastName')"
          />
        </div>
        <div>
          <BaseInput
            id="email"
            v-model="userState.email.input"
            label="Email"
            type="text"
            placeholder="name@company.com"
            :tabindex="3"
            :required="true"
            :error="
              !userState.email.isValid && userState.email.message.length > 0
            "
            :error-message="userState.email.message"
            :is-valid="userState.email.isValid"
            :blur="() => validateInput('email')"
          >
          </BaseInput>
        </div>
        <div class="flex flex-col justify-around">
          <div class="flex">
            <label for="id" class="flex text-sm font-medium text-primary">
              User Role(s)
            </label>
            <ToolTip for="roles"
              >Changing a user's assigned role(s) will add or remove
              capabilities within auxo</ToolTip
            >
          </div>
          <div class="flex justify-between">
            <div v-for="(value, key, index) in USER_ROLES_MAP" :key="key">
              <CheckBoxInput
                v-model="userState.roles.input[key]"
                class="flex-1 !p-0 !m-0 !mb-1.5"
                :tabindex="4 + index"
                :label="value"
                :disabled="restrictSelfAdminRemoval() && key == 'ADM'"
                :error="userState.roles.attempt == true && !hasAtLeastOneRole"
                :blur="() => (userState.roles.attempt = true)"
              />
            </div>
          </div>
        </div>
        <AvatarUpload
          :user-detail-prop="{
            initials: userInitials,
            photo_url: userState.photo.input,
          }"
          :chosen-file-name="userState.photo.fileName"
          :error="!userState.photo.isValid && userState.photo.message.length"
          :error-message="userState.photo.message"
          :show-temp-message="userState.photo.showTempMessage"
          @file-selected="
            fileName => {
              userState.photo.fileName = fileName;
              userState.photo.showTempMessage = false;
            }
          "
          @avatar-uploaded="
            photoUrl => {
              userState.photo.input = photoUrl;
              userState.photo.message = '';
              userState.photo.isValid = true;
            }
          "
          @avatar-removed="
            () => {
              userState.photo.input = 'none';
              userState.photo.fileName = 'No file chosen';
              userState.photo.message = '';
              userState.photo.isValid = true;
              userState.photo.showTempMessage = false;
            }
          "
          @file-size-error="
            () => {
              if (!userState.photo.isValid) {
                userState.photo.message =
                  'Selected file is too large. Max file size is 800 KB';
                userState.photo.fileName = 'No File Selected';
                userState.photo.isValid = false;
              } else {
                userState.photo.message = 'Selected file was more than 800 KB';
                userState.photo.showTempMessage = true;
              }
            }
          "
          @file-upload-error="
            () => {
              userState.photo.message =
                'We encountered an error while uploading your file, please try again';
              userState.photo.showTempMessage = true;
            }
          "
        />
        <div v-if="adminStore.modalAction == 'edit'">
          <div class="mb-2 text-sm font-medium text-primary">Status</div>
          <label
            for="user-status"
            :tabindex="7"
            onkeydown="if(event.key === ' ' || event.key === 'Spacebar') { event.preventDefault(); document.getElementById('user-status').click(); }"
            class="inline-flex relative items-center cursor-pointer"
          >
            <input
              id="user-status"
              v-model="userState.is_active.input"
              type="checkbox"
              value=""
              class="sr-only peer"
            />
            <div
              class="w-11 h-6 bg-bad-red !outline-none rounded-full peer-checked:after:!translate-x-full after:!border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:!transition-all peer-checked:!bg-good-green"
            ></div>
            <span
              v-if="userState.is_active.input"
              class="ml-3 text-sm font-medium text-good-green"
              >Active</span
            >
            <span v-else class="ml-3 text-sm font-medium text-bad-red"
              >Inactive</span
            >
          </label>
        </div>
      </div>
      <InfoPanel
        v-if="!userState.is_active.input && adminStore.modalAction == 'edit'"
        type="warning"
      >
        Once deactivated, this user will be logged out immediately and will no
        longer have access to auxo. They can be re-activated, if necessary.
        Their user activity will not be deleted.
      </InfoPanel>
      <InfoPanel
        v-if="userState.is_active.input || adminStore.modalAction == 'create'"
        type="alert"
      >
        This user must also be granted access through the company's SSO provider
        and their email address provided in auxo must be identical to the email
        stored by the Identity Provider (IdP) or the user will not be able to
        access auxo.
      </InfoPanel>
      <InfoPanel
        v-if="
          didRoleChange &&
          adminStore.modalAction == 'edit' &&
          userState.is_active.input
        "
        type="warning"
      >
        Changes to a user's role won't take effect until they log out of auxo
        and log back in.
      </InfoPanel>
      <div class="flex items-center space-x-4">
        <OutlinedButton type="button" tabindex="8" @click="closeModal">
          Cancel
        </OutlinedButton>
        <SolidButton type="submit" :disabled="!isReadyForSubmit" :tabindex="9">
          <img
            v-if="adminStore.modalAction == 'create'"
            class="h-5 w-5 mr-1.5 -ml-1"
            :src="PlusIcon"
          />
          {{ adminStore.modalAction == 'edit' ? 'Update user' : ' Add user' }}
        </SolidButton>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, watch, inject, onBeforeUnmount } from 'vue';
import { useAdminStore } from '@/stores/useAdmin';
import { useUsersStore } from '@/stores/useUsers';
import { toast } from 'vue3-toastify';
import isEqual from 'lodash/isEqual';
import BaseInput from '@/components/forms/BaseInputFlowBite.vue';
import CheckBoxInput from '@/components/forms/CheckBoxInputFlowBite.vue';
import ToolTip from '@/components/forms/ToolTip.vue';
import OutlinedButton from '@/components/buttons/OutlinedButtonFlowBite.vue';
import SolidButton from '@/components/buttons/SolidButtonFlowBite.vue';
import AvatarUpload from '@/components/forms/AvatarUpload.vue';
import PlusIcon from '@/assets/plus-icon.svg';
import InfoPanel from '@/components/InfoPanel.vue';

const adminStore = useAdminStore();
const usersStore = useUsersStore();

const userModalControls = inject('userModalControls');

const USER_ROLES_MAP = {
  REP: 'Contributor',
  SUP: 'Supporter',
  MGR: 'Leader',
  ADM: 'Admin',
};

const formSubmitted = ref(false);

const userState = ref({
  firstName: {
    isValid: false,
    message: '',
    input: '',
  },
  lastName: {
    isValid: false,
    message: '',
    input: '',
  },
  email: {
    isValid: false,
    message: '',
    input: '',
  },
  photo: {
    isValid: true,
    message: '',
    input: 'none',
    fileName: 'No file chosen',
    showTempMessage: false,
  },
  roles: {
    isValid: false,
    message: '',
    input: { REP: false, MGR: false, ADM: false },
  },
  is_active: {
    input: false,
    isValid: true,
  },
});

watch(
  () => userState.value.roles.input,
  newValue => {
    const hasValidRole = Object.values(newValue).some(role => role === true);
    userState.value.roles.isValid = hasValidRole;
  },
  { deep: true }
);

watch(
  () => adminStore.selectedUser,
  selectedUser => {
    resetFormData();
    if (selectedUser.first_name) {
      userState.value.firstName.input = selectedUser.first_name;
      userState.value.lastName.input = selectedUser.last_name;
      userState.value.email.input = selectedUser.email;
      userState.value.photo.input = selectedUser.photo_url;
      userState.value.is_active.input = selectedUser.is_active;
      selectedUser.user_roles.forEach(
        role => (userState.value.roles.input[role] = true)
      );
      userState.value.firstName.isValid = true;
      userState.value.lastName.isValid = true;
      userState.value.email.isValid = true;
      userState.value.photo.isValid = true;
      userState.value.is_active.isValid = true;
      userState.value.roles.isValid = true;
    }
  },
  { deep: true }
);

watch(
  () => adminStore.modalAction,
  modalAction => {
    if (modalAction == 'create') {
      resetFormData();
    }
  }
);

function resetFormData() {
  userState.value = {
    firstName: {
      isValid: false,
      message: '',
      input: '',
    },
    lastName: {
      isValid: false,
      message: '',
      input: '',
    },
    email: {
      isValid: false,
      message: '',
      input: '',
    },
    photo: {
      isValid: true,
      message: '',
      input: 'none',
      fileName: 'No file chosen',
    },
    roles: {
      isValid: false,
      attempt: false,
      input: { REP: false, MGR: false, ADM: false },
    },
    is_active: {
      input: false,
      isValid: true,
    },
  };
}

const userInitials = computed(() => {
  const { firstName, lastName } = userState.value;
  let firstInitial = '';
  let lastInitial = '';
  if (firstName.input.length && lastName.input.length) {
    firstInitial = firstName.input.trim().length
      ? firstName.input.trim()[0].toUpperCase()
      : '';
    lastInitial = lastName.input.trim().length
      ? lastName.input.trim()[0].toUpperCase()
      : '';
  }
  return `${firstInitial}${lastInitial}`;
});

function validateInput(field) {
  const userInput = userState.value[field].input;

  if (field !== 'email') {
    if (!validateCharacterLength(field)) return;
    if (!validateSpecialCharacters(userInput, field)) return;
  }

  if (field === 'email') validateEmail(userInput, field);
}

const isReadyForSubmit = computed(() => {
  if (adminStore.modalAction == 'create') {
    return isFormValid.value && !formSubmitted.value;
  } else {
    return isUpdateValid.value && isFormValid.value && !formSubmitted.value;
  }
});

const isFormValid = computed(() => {
  return Object.values(userState.value).every(field => field.isValid);
});

const isUpdateValid = computed(() => {
  if (Object.keys(adminStore.selectedUser).length === 0) return false;

  const {
    firstName,
    lastName,
    email: user_email,
    photo,
    roles,
    is_active,
  } = userState.value;

  const formData = {
    first_name: firstName.input,
    last_name: lastName.input,
    email: user_email.input,
    photo_url: photo.input,
    user_roles: Object.keys(roles.input)
      .filter(key => roles.input[key])
      .sort(),
    is_active: is_active.input,
  };

  const {
    first_name: selected_first_name,
    last_name: selected_last_name,
    email: selected_email,
    photo_url: selected_photo_url,
    user_roles: [...selected_user_roles],
    is_active: selected_is_active,
  } = adminStore.selectedUser;

  const savedData = {
    first_name: selected_first_name,
    last_name: selected_last_name,
    email: selected_email,
    photo_url: selected_photo_url,
    user_roles: selected_user_roles.sort(),
    is_active: selected_is_active,
  };

  const hasSelectedRole = formData.user_roles.length > 0;

  return !isEqual(formData, savedData) && hasSelectedRole;
});

const didRoleChange = computed(() => {
  if (Object.keys(adminStore.selectedUser).length === 0) return false;
  const { roles } = userState.value;
  const selectedRoles = Object.keys(roles.input)
    .filter(key => roles.input[key])
    .sort();

  const { user_roles } = adminStore.selectedUser;

  const sorted_roles = [...user_roles].sort();

  return !isEqual(selectedRoles, sorted_roles);
});

function validateCharacterLength(fieldName) {
  const minCharacterLength = 1;
  const maxCharacterLength = 50;
  const fieldLength = userState.value[fieldName].input.trim().length;

  const isValid =
    fieldLength >= minCharacterLength && fieldLength <= maxCharacterLength;

  if (!isValid) {
    setFieldValidity(
      fieldName,
      isValid,
      `This field must be between ${minCharacterLength} and ${maxCharacterLength} characters!`
    );
    return isValid;
  }
  setFieldValidity(fieldName, isValid);
  return isValid;
}

function validateSpecialCharacters(userInput, field) {
  const regexPattern =
    /^[a-zA-Z0-9\s.'àèìòùáéíóúýâêîôûãñõäëïöüÿÀÈÌÒÙÁÉÍÓÚÝÂÊÎÔÛÃÑÕÄËÏÖÜŸ-]+$/u;
  const isValid = regexPattern.test(userInput);
  if (!isValid) {
    const invalidCharacters = Array.from(
      new Set(userInput.split('').filter(char => !regexPattern.test(char)))
    );

    setFieldValidity(
      field,
      isValid,
      `${
        invalidCharacters.length > 1 ? 'Characters' : 'Character'
      }: ${invalidCharacters} is not supported`
    );
    return isValid;
  }

  setFieldValidity(field, isValid);
  return isValid;
}

function validateEmail(userInput, field) {
  const existingEmails = adminStore.existingEmails;
  const selectedEmail = adminStore.selectedUser.email
    ? adminStore.selectedUser.email.toLowerCase()
    : '';
  const isCurrentEmail = userInput.toLowerCase() == selectedEmail;
  const isUniqueEmail = !existingEmails.includes(userInput.toLowerCase());
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userInput);

  if (!isUniqueEmail && !isCurrentEmail) {
    setFieldValidity(field, isUniqueEmail, 'This email already exists in auxo');
    return isUniqueEmail;
  } else {
    setFieldValidity(field, isUniqueEmail);
  }

  if (!isValidEmail) {
    setFieldValidity(field, isValidEmail, 'This is not a valid email address');
    return;
  } else {
    setFieldValidity(field, isValidEmail);
  }

  validateCharacterLength('email');
}

const hasAtLeastOneRole = computed(() => {
  const { roles } = userState.value;

  const selected_roles = Object.keys(roles.input).filter(
    key => roles.input[key]
  );

  return selected_roles.length > 0;
});

function setFieldValidity(field, isValid, message = '') {
  userState.value[field].message = message;
  userState.value[field].isValid = isValid;
}

async function submit(event) {
  event.preventDefault();
  formSubmitted.value = true;
  const { firstName, lastName, email, photo, roles, is_active } =
    userState.value;

  const firstName_data = firstName.input.trim();
  const lastName_data = lastName.input.trim();
  const email_data = email.input.trim();
  const photo_data = photo.input;
  const is_active_data = is_active.input;
  const user_roles_data = Object.keys(roles.input).filter(
    key => roles.input[key]
  );

  try {
    if (adminStore.modalAction == 'create') {
      await createUser(
        firstName_data,
        lastName_data,
        email_data,
        photo_data,
        user_roles_data
      );
    } else {
      await updateUser(
        firstName_data,
        lastName_data,
        email_data,
        photo_data,
        is_active_data,
        user_roles_data
      );
    }
    await adminStore.fetchExistingEmails();
  } catch (error) {
    toast.error('Oops! We encountered an error, please try again');
  }
  closeModal();
}

async function createUser(firstName, lastName, email, photo, user_roles) {
  const response = await adminStore.createUser(
    firstName,
    lastName,
    email,
    photo,
    user_roles
  );

  if (response) {
    toast.success('auxo user created!');
    adminStore.getUsers(adminStore.tableViewFilter);
  } else {
    toast.error('Error while creating auxo user');
  }
}

async function updateUser(
  firstName,
  lastName,
  email,
  photo,
  is_active,
  user_roles
) {
  const auxo_user_id = adminStore.selectedUser.auxo_user_id;
  const response = await adminStore.updateUser(
    auxo_user_id,
    firstName,
    lastName,
    email,
    photo,
    is_active,
    user_roles
  );

  if (response) {
    toast.success('auxo user updated!');
    adminStore.getUsers(adminStore.tableViewFilter);
    if (email === usersStore.activeUser.email) {
      await usersStore.getUserByEmail(email).then(value => {
        usersStore.getAllUsers();
        value.photo_url
          ? (usersStore.activeUser.photo_url = value.photo_url)
          : (usersStore.activeUser.photo_url = 'none');
        usersStore.activeUser.initials = [
          value.first_name[0].toUpperCase(),
          value.last_name[0].toUpperCase(),
        ].join('');
      });
    }
    usersStore.getAllUsers();
  } else {
    toast.error('Error while updating auxo user');
  }
}

function restrictSelfAdminRemoval() {
  if (
    usersStore.activeUser?.auxo_user_id ==
    adminStore?.selectedUser?.auxo_user_id
  ) {
    return true;
  }
  return false;
}

function closeModal() {
  resetFormData();
  formSubmitted.value = false;
  adminStore.selectedUser = {};
  userModalControls.value.hide();
}

/** ensures backdrop is taken away if user navigates away without
 * explicilty closing the modal
 */
onBeforeUnmount(() => {
  userModalControls.value.hide();
});
</script>
