<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      :class="props.strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  strokeColor: {
    default: 'stroke-white',
    value: String,
  },
});
</script>
