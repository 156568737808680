<template>
  <svg
    :class="['w-3 h-3', props.textColor]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 8 14"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  textColor: {
    default: 'gray-400',
    type: String,
  },
});
</script>
