<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <path
      id="iconmonstr-pencil-thin"
      d="M8.071,21.586,1,23l1.414-7.071L17.343,1,23,6.657Zm-.493-.921L3.335,16.422l-1.06,5.3ZM17.343,2.414l-13.3,13.3,4.242,4.242,13.3-13.3L17.343,2.414Z"
      transform="translate(-1 -1)"
      class="fill-gray-500"
      fill-rule="evenodd"
    />
  </svg>
</template>
