<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.271"
    height="14.272"
    viewBox="0 0 14.271 14.272"
    stroke-width="2"
  >
    <path
      id="Path_1251"
      data-name="Path 1251"
      d="M13.008,9.886l4.8-4.8a.713.713,0,0,0,0-1.007L16.7,2.958a.713.713,0,0,0-1.007,0l-4.8,4.8-4.8-4.8a.713.713,0,0,0-1.007,0L3.959,4.075a.713.713,0,0,0,0,1.007l4.8,4.8-4.8,4.8a.713.713,0,0,0,0,1.007l1.116,1.116a.713.713,0,0,0,1.007,0l4.8-4.8,4.8,4.8a.713.713,0,0,0,1.007,0L17.813,15.7a.713.713,0,0,0,0-1.007Z"
      transform="translate(-3.75 -2.75)"
      class="fill-primary"
    />
  </svg>
</template>
