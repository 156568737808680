<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="-100 -100 400 394"
    enable-background="new -100 -100 400 394"
    xml:space="preserve"
  >
    <circle cx="100" cy="97" r="200" class="fill-primary" />
    <path
      class="fill-white"
      opacity="1.000000"
      stroke="none"
      d="
M201.000000,104.531342 
	C199.406876,110.653618 197.921890,116.340370 196.201721,121.955063 
	C189.112488,145.094513 175.404037,163.377441 155.173676,176.666046 
	C154.780167,167.095123 154.730576,157.945633 154.681000,148.796158 
	C171.283813,131.428391 178.896378,110.745796 178.373672,86.725571 
	C177.960617,67.744621 178.290619,48.747505 178.290634,29.757570 
	C178.290649,27.965862 178.290634,26.174154 178.290634,24.236086 
	C177.266708,24.061655 176.642654,23.862642 176.018524,23.862375 
	C150.532166,23.851482 125.040474,23.528679 99.560684,23.940128 
	C60.852074,24.565197 30.272413,52.045506 24.872475,90.509102 
	C24.341702,94.289787 23.882004,98.118767 23.866831,101.927238 
	C23.774599,125.076660 23.820820,148.226624 23.820820,172.195160 
	C30.613037,172.195160 37.364273,172.195160 44.590775,172.704559 
	C45.044022,180.475983 45.022011,187.737991 45.000000,195.000000 
	C30.376818,195.000000 15.753635,195.000000 1.000000,195.000000 
	C1.000000,160.645874 1.000000,126.291595 1.364666,91.307068 
	C2.073215,88.883675 2.397305,87.086479 2.763880,85.297997 
	C11.464318,42.849396 35.781853,14.802110 78.225113,3.464411 
	C81.160080,2.680402 84.075493,1.823176 87.000000,1.000000 
	C124.923592,1.000000 162.847183,1.000000 201.000000,1.000000 
	C201.000000,35.353889 201.000000,69.708282 201.000000,104.531342 
z"
    />
    <path
      class="fill-white"
      opacity="1.000000"
      stroke="none"
      d="
M88.468536,195.000000 
	C87.981850,170.695496 87.940720,146.390915 88.000145,122.086601 
	C88.003891,120.557030 88.323235,118.575340 89.291801,117.573936 
	C95.981567,110.657349 102.882820,103.945335 110.295486,96.592926 
	C110.430603,98.672943 110.599640,100.068443 110.600380,101.464035 
	C110.615829,130.763107 110.632896,160.062225 110.557899,189.361115 
	C110.553673,191.008011 109.760643,192.652893 109.167374,194.649353 
	C102.312355,195.000000 95.624710,195.000000 88.468536,195.000000 
z"
    />
    <path
      class="fill-tertiary"
      opacity="1.000000"
      stroke="none"
      d="
M45.468628,195.000000 
	C45.022011,187.737991 45.044022,180.475983 45.058212,172.769272 
	C43.696640,163.850128 47.698044,157.977325 53.896751,152.755798 
	C58.651493,148.750595 62.794823,144.019547 68.025345,138.797333 
	C68.025345,157.997238 68.025345,176.030106 68.012672,194.531479 
	C60.645748,195.000000 53.291500,195.000000 45.468628,195.000000 
z"
    />
    <path
      class="fill-secondary"
      opacity="1.000000"
      stroke="none"
      d="
M154.528473,148.432526 
	C154.730576,157.945633 154.780167,167.095123 154.816498,176.732788 
	C147.525162,181.354095 140.247070,185.487228 131.827103,190.268829 
	C131.827103,187.009262 131.827118,185.418701 131.827103,183.828140 
	C131.826752,149.008881 131.758850,114.189217 131.962067,79.371147 
	C131.977325,76.757126 133.311523,73.587334 135.092514,71.650558 
	C140.917435,65.316147 147.232666,59.432621 154.375961,52.370716 
	C154.375961,85.022049 154.375961,116.545471 154.528473,148.432526 
z"
    />
  </svg>
</template>
