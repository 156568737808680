import { defineStore } from 'pinia';
import { apiPost } from '@/utils/api';
import getPath from '@/utils/getPath';

export const useSearchStore = defineStore('Search', {
  state: () => ({
    searchResults: [],
    searchModalControls: null,
    isSearching: false, // loading
    justSearched: false,
    returningFromPreviousSearch: false,
    input: '',
    previousRoute: '',
    searchRecords: 0,
    searchPage: 1,
    searchOffset: 0,
    searchLimit: 10,
    searchDirection: 'ASC',
    searchField: 'patient_name',
  }),
  getters: {},
  actions: {
    setJustSearched(val) {
      this.justSearched = val;
    },
    showSearchModal() {
      this.searchModalControls.show();
    },
    hideSearchModal() {
      this.searchModalControls.hide();
    },
    clear() {
      this.searchResults = [];
      this.input = '';
      this.searchRecords = 0;
      this.searchPage = 1;
      this.searchDirection = 'ASC';
      this.searchField = 'patient_name';
    },
    close() {
      this.searchResults = [];
      this.input = '';
      this.searchPage = 1;
      this.searchDirection = 'ASC';
      this.searchField = 'patient_name';
      this.searchModalControls.hide();
    },
    async queryBySearchTerm(
      query = this.input,
      page = 1,
      direction = 'ASC',
      field = 'patient_name'
    ) {
      this.searchPage = page;
      this.searchDirection = direction.toUpperCase();
      this.searchField = field;
      this.isSearching = true;
      direction = this.searchDirection;
      const limit = this.searchLimit;
      this.searchLimit = limit;
      const payload = {
        query,
        limit,
        offset: page ? (page - 1) * limit : this.searchOffset,
        direction,
        field,
      };

      try {
        const response = await apiPost(getPath(`workItems/query`), payload);
        this.searchResults = response?.data?.rows || [];
        this.searchRecords = response?.data?.records || 0;
        return this.searchResults;
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isSearching = false;
      }
    },
  },
});
