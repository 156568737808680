<template>
  <div class="drop-shadow-md relative">
    <BaseInput
      :disabled="props.disabled"
      v-model="query_terms"
      class="h-[34px] pl-8 px-auto gray-border bg-white mb-[-1px]"
      placeholder="Search worklists"
      @keyup.enter="search()"
    >
    </BaseInput>
    <MagnifyGlassIcon
      class="absolute top-3.5 left-2 w-5 h-5"
      stroke-color="stroke-gray-500"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useGamePlanStore } from '@/stores/useGamePlans';
import BaseInput from '../../components/forms/BaseInput.vue';
import MagnifyGlassIcon from '@/assets/sidebar-icons/FlowbiteSearchIcon.vue';
const STRATEGY_ID = 1;

const gameplan_store = useGamePlanStore();

const query_terms = ref('');

const props = defineProps({
  disabled: {
    default: false,
    value: Boolean,
  },
});

const search = () => {
  const execId = gameplan_store.getCurrentExecId;
  gameplan_store.getWorkLists(STRATEGY_ID, execId, query_terms.value);
};

watch(
  () => query_terms.value,
  newValue => {
    if (newValue != '') {
      emit('hasQueryTerms', newValue);
    }
  }
);

//Clear the search field when user clicks edit game plan
watch(
  () => props.disabled,
  () => {
    query_terms.value = '';
  }
);

const emit = defineEmits(['hasQueryTerms']);
</script>

<style scoped>
.gray-border {
  outline: lightgrey 1px solid;
  border-radius: 0.25rem;
}
.gray-border:focus {
  outline: red 1px solid !important;
  border-radius: 8px;
}
</style>
