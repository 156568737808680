<template>
  <li>
    <router-link :to="path">
      <div
        class="flex items-center p-2 text-base font-normal rounded-lg hover:bg-secondary group transition-all duration-300"
        :class="{
          'justify-center': collapsed,
          'pl-3.5': !collapsed,
          'w-[3.5rem]': collapsed,
          'w-full': !collapsed,
          'bg-sky-400': route.path == path,
          'bg-secondary': route.path == path,
        }"
      >
        <component
          :is="icon"
          class="w-7 h-7"
          :class="{ 'fill-white': route.path == path }"
        />
        <span
          v-show="!collapsed && !collapsedAfterAnimations"
          class="ml-3"
          :class="{
            'pl-[2rem]': nestedLevel == 1,
            'pl-[3rem]': nestedLevel == 2,
            'pl-[4rem]': nestedLevel == 3,
          }"
        >
          {{ name }}
        </span>
      </div>
    </router-link>
  </li>
</template>
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const props = defineProps({
  collapsed: {
    default: false,
    type: Boolean,
  },
  collapsedAfterAnimations: {
    default: false,
    type: Boolean,
  },
  path: {
    default: '',
    type: String,
  },
  name: {
    default: '',
    type: String,
  },
  nestedLevel: {
    default: 0,
    type: Number,
  },
  icon: {
    default: null,
    type: Object,
  },
});
</script>
