<!-- 
Renders list of options passed in on drop down,
user can select/search multiple options 
-->
<template>
  <div
    :class="{
      error: props.error,
      'focused-input': props.focused,
      'unfocused-input': !props.focused,
      'multiselect-input': true,
    }"
  >
    <!-- https://vue-select.org/ -->
    <v-select
      v-model="multiVal"
      :label="props.label"
      :reduce="el => el[props.reduceKey] || el"
      :loading="selectLoading"
      multiple
      :close-on-select="false"
      :options="options"
      :disabled="props.disabled"
      :clear-search-on-select="false"
      :dropdown-should-open="
        ({ noDrop, open, mutableLoading }) => {
          if (props.disabled) return false;
          if (open) setOpen(true);
          else setOpen(false);
          return noDrop ? false : (open && !mutableLoading) || focused;
        }
      "
      @update:modelValue="vals => props.setValue(vals)"
      @option:selecting="
        selectedOption => {
          // fires when options from the actual dropdown or selected/deselected
          if (multiVal) {
            multiVal = multiVal.filter(
              e => e !== selectedOption[props.reduceKey]
            );
            props.setValue(multiVal);
          }
        }
      "
    >
      <template #spinner="{ loading }">
        <div v-if="loading" role="status">
          <loading-spinner />
        </div>
      </template>
      <template
        #selected-option-container="{
          option,
          multiple,
          disabled: isDisabled,
          deselect,
        }"
      >
        <!-- Chip Previews in serachbox, when x is clicked deselect fires -->
        <!-- if else renders nothing when loading -->
        <!-- prevents id number being shown before label fetch is finished -->
        <div v-if="!selectLoading" class="vs__selected">
          {{ option[props.label] }}
          <button
            v-if="multiple && !props.disabled"
            ref="deselectButtons"
            :disabled="isDisabled"
            type="button"
            class="vs__deselect"
            :title="`Deselect ${option[props.label]}`"
            :aria-label="`Deselect ${option[props.label]}`"
            @click="() => deselect(option)"
          >
            <CloseIcon2 />
          </button>
        </div>
        <div v-else class="vs_selected"></div>
      </template>
      <!-- render of option in dropdown view -->
      <template #option="option">
        <div
          :class="{
            checked: isChecked(option) == true,
          }"
          @click="
            () => {
              if (isChecked(option)) {
                const newValue = [
                  ...multiVal.filter(e => e != option[props.reduceKey]),
                ];
                multiVal.value = newValue;
              }
            }
          "
        >
          <input
            :id="`option-id-${option[props.label]}`"
            type="checkbox"
            class="pointer-events-none"
            :checked="isChecked(option) == true"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            "
          />
          {{ option[props.label] }}
        </div>
      </template>
      <template #open-indicator="{ attributes }">
        <button
          v-if="multiVal?.length && !props.disabled"
          @click="
            e => {
              e.preventDefault();
              e.stopPropagation();
              reset();
              return false;
            }
          "
        >
          <img :src="CloseIconSVG" class="w-4 h-4 mr-1" />
        </button>

        <img
          v-show="options.length"
          :src="DownArrowSVG"
          v-bind="attributes"
          class="h-3 cursor-pointer ml-1"
        />
      </template>
      <template #no-options="{ search }">
        <div v-if="search.length === 0">Search</div>
        <div v-else>
          <div v-if="selectLoading">Loading...</div>
          <div v-else>No values found.</div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import DownArrowSVG from '@/assets/down-arrow.svg';
import CloseIconSVG from '@/assets/close-icon.svg';
import isEqual from 'lodash/isEqual';
import CloseIcon2 from '@/assets/CloseIcon2.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

const multiVal = ref([]);
const options = ref([]);
const selectLoading = ref(false);

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  /**
   * key name of dict to be displayed in options list
   *
   * ex: [{'id': 1, 'text': 'Apple'}, {'id': 2, 'text': 'Orange'}]
   *
   * label = 'text'
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * Determines what value is stored in values array
   *
   * For ex reduceKey is usually going to be the id field we want to
   * send to the backend
   *
   * ex: [{'id': 1, 'text': 'Apple'}, {'id': 2, 'text': 'Orange'}]
   * reduceKey="id"
   */
  reduceKey: {
    type: String,
    default: '',
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
  setValue: {
    type: Function,
    default: () => {},
  },
  rule: {
    type: Object,
    default: () => {},
  },
  /**
   * Fetch function that retrieves items to be displayed
   */
  fetchOptions: {
    type: Function,
    default: () => {},
  },
  /**
   * Variable in given store that stores fetched values
   */
  getOptions: {
    type: Array,
    default: () => [],
  },
});

/**
 * Fetch options for dropdown
 */
onMounted(async () => {
  selectLoading.value = true;
  await props.fetchOptions();
  options.value = props.getOptions;
  selectLoading.value = false;
});

/**
 * First dropdown was changed
 * New filter option selected
 */
watch(
  () => props.rule.operator,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    else {
      reset();
    }
  }
);

/**
 * When a worklist is in edit mode this updates our component
 * to show the previously selected options
 */
watch(
  () => props.value,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    else {
      multiVal.value = newValue;
    }
  }
);

// Reset component state
const reset = () => {
  multiVal.value = [];
  props.setValue([]);
};

// Is a checkbox checked
const isChecked = option => {
  if (multiVal.value)
    return (
      multiVal.value.findIndex(el => el === option[props.reduceKey]) !== -1
    );
};
</script>

<style>
.focused-input .vs__selected-options,
.focused-input .vs__dropdown-toggle {
  height: inherit !important;
}

.unfocused-input .vs__selected-options,
.unfocused-input .vs__dropdown-toggle {
  height: 36px !important;
  overflow: hidden;
}

.multiselect-input .vs__selected {
  height: fit-content;
}

.multiselect-input .vs__dropdown-option {
  padding: 0;
}

.multiselect-input .vs__dropdown-option > div {
  padding: 3px 20px;
}

.multiselect-input .vs__dropdown-option > div.checked:hover {
  @apply bg-gray-300;
}
</style>
