import { defineStore } from 'pinia';
import {
  FAKE_WORK_GAP_DATA,
  fetchAndSortData,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
  PAYER_PLAN_OPTIONS,
  PHASE_STATUS_REASON_OPTIONS,
  ACTIVITY_TYPE_OPTIONS,
  BILLING_ENTITY_OPTIONS,
  FINANCIAL_CLASS_OPTIONS,
} from '@/utils/ogFakeData';

export const useActivityStatsStore = defineStore('activityStats', {
  state: () => ({
    searchLimit: 15,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'user',
    totalRows: 0,

    fakeWorkGapData: [],
    OGFakeWorkGapData: FAKE_WORK_GAP_DATA,

    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
    lastUpdated: '10/31/2024',

    selectedUser: [],
    selectedBillingType: [],
    selectedActivityType: [],
    selectedPhaseStatusReason: [],
    selectedPayerPlan: [],
    selectedFinancialClass: [],
    selectedBillingEntity: [],
    filtersApplied: [],
    currentMonth: 'October 2025',
    monthlyTotal: { value: 27 },
    previousSixMonthsAverage: { value: 22 },
    monthlyVS6MonthAverageCount: {
      value: 5,
      colorTernary: 1,
      caretTernary: 2,
    },
    monthlyVS6MonthAveragePercent: {
      value: '22.7%',
      colorTernary: 2,
      caretTernary: 1,
    },
    monthlyInsights: [
      {
        key: 'highestMonth',
        value: 23,
        textValue: 'August 2024',
        labelText: 'Highest Month:',
        colorTernary: 1,
      },
      {
        key: 'lowestMonth',
        value: 5,
        textValue: 'October 2024',
        labelText: 'Lowest Month:',
        colorTernary: 2,
      },
      {
        key: 'currentMonth',
        value: 42,
        textValue: 'October 2024',
        labelText: 'Current Month:',
        colorTernary: 0,
        showWarning: true,
      },
    ],
    mostActiveDays: [
      { value: 21, textValue: 'Monday' },
      { value: 15, textValue: 'Tuesday' },
      { value: 12, textValue: 'Friday' },
    ],
    leaderboard: [
      { username: 'Diane', total: '15' },
      { username: 'Casey', total: '14' },
      { username: 'Robert', total: '13' },
      { username: 'Dan', total: '11' },
      { username: 'Sam', total: '10' },
      { username: 'Nirav', total: '9' },
      { username: 'Tom', total: '9' },
      { username: 'Jeremy', total: '8' },
      { username: 'Jaideep', total: '7' },
      { username: 'Weston', total: '6' },
    ],
    activityCodes: [
      { codeName: 'Appeal Sent', total: '17' },
      { codeName: 'Adjustment - Requested', total: '16' },
      { codeName: 'Billed - Corrected Claim/Rebill', total: '14' },
      { codeName: 'Pending - Appeal in Process', total: '12' },
      { codeName: 'Triaged - Coding', total: '10' },
    ],
    isLoading: false,
    //For modal
    activityTypeOptions: ACTIVITY_TYPE_OPTIONS,
    billingEntityOptions: BILLING_ENTITY_OPTIONS,
    financialClassOptions: FINANCIAL_CLASS_OPTIONS,
    payerPlanDDOptions: PAYER_PLAN_OPTIONS,
    phaseStatusReasonOptions: PHASE_STATUS_REASON_OPTIONS,
  }),
  getters: {
    getFiltersApplied: state => {
      return state.filtersApplied;
    },
  },
  actions: {
    async thisWillHaveABetterName(page = 1, direction = 'asc', field = 'user') {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };

      try {
        const response = await fetchAndSortData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeWorkGapData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
    resetFilters() {
      this.selectedUser = [];
      this.selectedActivityGap = [];
      this.selectedUser = [];
      this.selectedBillingType = [];
      this.selectedActivityType = [];
      this.selectedPhaseStatusReason = [];
      this.selectedPayerPlan = [];
      this.selectedFinancialClass = [];
      this.selectedBillingEntity = [];
      this.startDate = DEFAULT_START_DATE;
      this.endDate = DEFAULT_END_DATE;
      this.filtersApplied = [];
    },
    applyFilters() {
      //There will be more here but this will do for now
      this.filtersApplied = [
        { name: 'Date Range Applied' },
        { name: 'Billing Entity Filter Applied' },
      ];
    },
  },
});
