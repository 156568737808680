export const navBarTabs = [
  { name: 'Overview', route: '/stats/workforce-management/gap' },
  {
    name: 'Breakout',
    route: '/stats/workforce-management/gap/breakout',
  },
  {
    name: 'Detail',
    route: '/stats/workforce-management/gap/detail',
  },
];
export default navBarTabs;
