<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class=""
  >
    <g clip-path="url(#clip0_1835_25014)">
      <path
        d="M10 0.5C8.02219 0.5 6.08879 1.08649 4.4443 2.1853C2.79981 3.28412 1.51809 4.8459 0.761209 6.67316C0.00433284 8.50042 -0.1937 10.5111 0.192152 12.4509C0.578004 14.3907 1.53041 16.1725 2.92894 17.5711C4.32746 18.9696 6.10929 19.922 8.0491 20.3078C9.98891 20.6937 11.9996 20.4957 13.8268 19.7388C15.6541 18.9819 17.2159 17.7002 18.3147 16.0557C19.4135 14.4112 20 12.4778 20 10.5C19.9971 7.84873 18.9426 5.30688 17.0679 3.43215C15.1931 1.55741 12.6513 0.502912 10 0.5ZM9.5 4.5C9.79667 4.5 10.0867 4.58797 10.3334 4.75279C10.58 4.91762 10.7723 5.15188 10.8858 5.42597C10.9994 5.70006 11.0291 6.00166 10.9712 6.29263C10.9133 6.58361 10.7704 6.85088 10.5607 7.06066C10.3509 7.27044 10.0836 7.4133 9.79264 7.47118C9.50167 7.52905 9.20007 7.49935 8.92598 7.38582C8.65189 7.27229 8.41762 7.08003 8.2528 6.83335C8.08798 6.58668 8 6.29667 8 6C8 5.60217 8.15804 5.22064 8.43934 4.93934C8.72065 4.65803 9.10218 4.5 9.5 4.5ZM12 15.5H8C7.73479 15.5 7.48043 15.3946 7.2929 15.2071C7.10536 15.0196 7 14.7652 7 14.5C7 14.2348 7.10536 13.9804 7.2929 13.7929C7.48043 13.6054 7.73479 13.5 8 13.5H9V10.5H8C7.73479 10.5 7.48043 10.3946 7.2929 10.2071C7.10536 10.0196 7 9.76521 7 9.5C7 9.23478 7.10536 8.98043 7.2929 8.79289C7.48043 8.60535 7.73479 8.5 8 8.5H10C10.2652 8.5 10.5196 8.60535 10.7071 8.79289C10.8946 8.98043 11 9.23478 11 9.5V13.5H12C12.2652 13.5 12.5196 13.6054 12.7071 13.7929C12.8946 13.9804 13 14.2348 13 14.5C13 14.7652 12.8946 15.0196 12.7071 15.2071C12.5196 15.3946 12.2652 15.5 12 15.5Z"
        class="fill-secondary"
      />
    </g>
    <defs>
      <clipPath id="clip0_1835_25014">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
