export const navBarTabs = [
  { name: 'Overview', route: '/stats/workforce-management/activity' },
  {
    name: 'Breakout',
    route: '/stats/workforce-management/activity/breakout',
  },
  {
    name: 'Detail',
    route: '/stats/workforce-management/activity/detail',
  },
];
export default navBarTabs;
